import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";

export default class ForgetPassword extends Component {
  constructor(props) {
    super(props);

    this.onChangeUsermail = this.onChangeUsermail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      usermail: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push("/dashboard");
    }
  }

  onChangeUsermail(e) {
    this.setState({
      usermail: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const user = {
      usermail: this.state.usermail,
    };

    axios
      .post(`${baseAPI}/users/resetpassword`, user)
      .then((response) => {
        console.log(response);
        toast.success(
          "Email con instrucciones enviado correctamente. Revisa tu bandeja de entrada",
          {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log(error);
          toast.error("Este usuario no existe", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          console.log(error);
          toast.error(
            "Error al enviar el email, contacte con el adminsitrador",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      });
  }

  render() {
    return (
      <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
        <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
          <section>
            <h3 className="font-bold text-2xl">Recupera tu contraseña</h3>
            <p className="text-gray-600 pt-2">
              {" "}
              Indica tu email para recuperar tu contraseña
            </p>
          </section>

          <section className="mt-10">
            <form
              className="flex flex-col"
              method="POST"
              action="#"
              onSubmit={this.onSubmit}
            >
              <div className="mb-6 pt-3 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  onChange={this.onChangeUsermail}
                  type="text"
                  id="email"
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                />
              </div>
              <div className="flex justify-end">
                <Link
                  className="text-sm text-blue-600 hover:text-blue-700 hover:underline mb-6"
                  to={`/login`}
                >
                  Volver al inicio de sesión
                </Link>
              </div>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
              >
                Recuperar contraseña
              </button>
            </form>
          </section>
        </main>

        <div className="max-w-lg mx-auto text-center mt-12 mb-6">
          <p className="text-white">
            ¿No tienes cuenta?{" "}
            <Link className="font-bold hover:underline" to={`/signup`}>
              Registrate gratis
            </Link>
          </p>
        </div>

        <footer className="max-w-lg mx-auto flex justify-center text-white">
          <a href="www.claimup.io" className="hover:underline">
            www.claimup.io
          </a>
        </footer>
      </div>
    );
  }
}
