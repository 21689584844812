import React, { useState, useEffect } from "react";
// import axios from "axios";
import jwt_decode from "jwt-decode";
// import { baseAPI } from "../../config";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";
import checkOutCreateExpedient from "./StripePayout";

function CreateExpedient() {
  const [importe_deuda, setImporteDeuda] = useState(0);
  const [user_id, setUserId] = useState("");
  const [role, setRole] = useState("");
  const [deudor, setDeudor] = useState("");
  const [domicilio_deudor, setDomicilioDeudor] = useState("");
  const [ciudad_deudor, setCiudadDeudor] = useState("");
  const [cp_deudor, setCpDeudor] = useState("");
  const [telefono_deudor, setTelefonoDeudor] = useState("");
  const [mail_deudor, setMailDeudor] = useState("");
  const [tipo_deuda, setTipoDeuda] = useState("");
  const [tipo_reclamacion] = useState("Extrajudicial");
  const [plan, setPlan] = useState("");
  const [presupuesto, setPresupuesto] = useState(300);
  const [porcentajeBudget, setPorcentajeBudget] = useState(0);
  const [servicios, setServicios] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwt_decode(userToken);
    const user_id = decoded.id;
    const userRole = decoded.role;
    setUserId(user_id);
    setRole(userRole);
    if (decoded.userDataComplete === false) {
      toast.error(
        "Por favor, completa tu perfil antes de crear una reclamación"
      );
      setTimeout(() => {
        window.location = `/profile/${user_id}`;
      }, 4000);
    }
  }, []);

  useEffect(() => {
    let servicios = [];
    let presupuesto = 300;
    let porcentajeBudget = 0;

    if (plan === "premium") {
      console.log("premium");
      presupuesto = 45;
      porcentajeBudget = 20;
      servicios = [
        {
          tipo: "burofax",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de burofax certificado al deudor",
          cantidad: 1,
          utilizados: 0,
        },
        {
          tipo: "llamada_robotizada",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada robotizada",
          cantidad: 10,
          utilizados: 0,
        },
        {
          tipo: "sms",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de sms",
          cantidad: 20,
          utilizados: 0,
        },
        {
          tipo: "email",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de email",
          cantidad: 50,
          utilizados: 0,
        },
        {
          tipo: "llamada_personal",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada personal",
          cantidad: 10,
          utilizados: 0,
        },
      ];
    }
    if (plan === "basic") {
      console.log("basic");
      presupuesto = 25;
      porcentajeBudget = 20;
      servicios = [
        {
          tipo: "llamada_robotizada",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada robotizada",
          cantidad: 5,
          utilizados: 0,
        },
        {
          tipo: "sms",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 1,
          detalles: "Envío de sms",
          cantidad: 10,
          utilizados: 0,
        },
        {
          tipo: "email",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 1,
          detalles: "Envío de email",
          cantidad: 20,
          utilizados: 0,
        },
        {
          tipo: "llamada_personal",
          fecha: Date.now(),
          estado: "pendiente",
          costo: 0,
          detalles: "Envío de llamada personal",
          cantidad: 5,
          utilizados: 0,
        },
      ];
    }
    setPresupuesto(presupuesto);
    setPorcentajeBudget(porcentajeBudget);
    setServicios(servicios);
  }, [plan]);

  const onChangeDeudor = (e) => {
    setDeudor(e.target.value);
  };

  const onChangeDomicilioDeudor = (e) => {
    setDomicilioDeudor(e.target.value);
  };

  const onChangeCiudadDeudor = (e) => {
    setCiudadDeudor(e.target.value);
  };

  const onChangeCpDeudor = (e) => {
    setCpDeudor(e.target.value);
  };

  const onChangeTelefonoDeudor = (e) => {
    setTelefonoDeudor(e.target.value);
  };
  const onChangeMailDeudor = (e) => {
    setMailDeudor(e.target.value);
  };

  const onChangeImporteDeuda = (value) => {
    setImporteDeuda(value);
  };

  const onChangeTipoDeuda = (e) => {
    setTipoDeuda(e.target.value);
  };

  const onchangePlan = (e) => {
    setPlan(e.target.id);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const expedient = {
      user_id: user_id,
      deudor: deudor,
      telefono_deudor: telefono_deudor,
      mail_deudor: mail_deudor,
      domicilio_deudor: domicilio_deudor,
      ciudad_deudor: ciudad_deudor,
      cp_deudor: cp_deudor,
      importe_deuda: importe_deuda,
      tipo_reclamacion: tipo_reclamacion,
      tipo_deuda: tipo_deuda,
      presupuesto: presupuesto,
      porcentaje_presupuesto: porcentajeBudget,
      servicios: servicios,
    };

    if (
      expedient.tipo_deuda === "-Selecciona una opción-" ||
      expedient.presupuesto === 0 ||
      plan === ""
    ) {
      toast.error("Por favor, completa todos los campos");
    } else {
      checkOutCreateExpedient(presupuesto, expedient, role);
    }
  };

  return (
    <div className="ml-4  bg-white shadow-lg rounded-sm border border-gray-200 w-full">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">
          Crear Expediente de Reclamación
        </h2>
      </header>
      <section className="px-5 py-4 w-full">
        <div className="container mt-5 w-full ">
          <form
            className="flex flex-col flex-wrap "
            method="POST"
            action="#"
            onSubmit={onSubmit}
          >
            <h2 className="block pb-3 text-blue-500 whitespace-normal md:whitespace-nowrap border-b-2 border-blue-500 w-full text-sm md:text-base">
              Información de la Deuda
            </h2>

            <div className="flex flex-wrap -mx-3 mb-6 mt-5 w-full">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-last-name"
                >
                  Cantidad a reclamar
                </label>
                <CurrencyFormat
                  id="grid-last-name"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder={"0 €"}
                  thousandSeparator={"."}
                  suffix={" €"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator={","}
                  onValueChange={(values) => {
                    const { value } = values;
                    onChangeImporteDeuda(parseFloat(value));
                  }}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 w-full">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-last-name"
                >
                  Tipo de deuda (seleccionar de las opciones)
                </label>
                <select
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeTipoDeuda}
                  required={true}
                  defaultValue="-Selecciona una opción-"
                >
                  <option>-Selecciona una opción-</option>
                  <option>Factura</option>
                  <option>Reconocimiento de deuda</option>
                  <option>Préstamo personal sin garantía</option>
                  <option>Préstamo hipotecario</option>
                  <option>Pagaré</option>
                  <option>Tarjeta de crédito</option>
                  <option>Tarjeta revolving</option>
                  <option>Impago suscripción recurrente</option>
                  <option>Contrato de arrendamiento</option>
                  <option>Otro tipo de deuda</option>
                </select>
              </div>
            </div>

            <h2 className="block pb-3 text-blue-500 whitespace-normal md:whitespace-nowrap border-b-2 border-blue-500 w-full text-sm md:text-base">
              Información del Deudor Principal (Podrás añadir información de
              otros deudores más adelante)
            </h2>

            <div className="flex flex-wrap -mx-3 mb-6 mt-5 w-full">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Nombre del Deudor
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Nombre"
                  onChange={onChangeDeudor}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Dirección del deudor (calle y número)
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Calle y número"
                  onChange={onChangeDomicilioDeudor}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Ciudad
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Ciudad Deudor"
                  onChange={onChangeCiudadDeudor}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Codigo Postal Deudor
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Codigo Postal"
                  onChange={onChangeCpDeudor}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Email del Deudor
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Email"
                  onChange={onChangeMailDeudor}
                  required={true}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-2"
                  htmlFor="grid-first-name"
                >
                  Teléfono del Deudor
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="grid-first-name"
                  type="text"
                  placeholder="Teléfono"
                  onChange={onChangeTelefonoDeudor}
                  required={true}
                />
              </div>
            </div>

            <h2 className="block pb-3 text-blue-500 whitespace-normal md:whitespace-nowrap border-b-2 border-blue-500 w-full text-sm md:text-base">
              Selecciona tu plan de reclamación
            </h2>

            <div className="flex flex-wrap -mx-3 mb-6 mt-5 w-full">
              {/* plan 1 */}
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className={`block cursor-pointer bg-white text-sm font-medium text-gray-800 p-4 rounded border ${
                    plan === "basic"
                      ? "bg-blue-100 border-blue-400"
                      : "border-gray-200"
                  } hover:border-gray-300 shadow-sm duration-150 ease-in-out w-full relative`}
                >
                  <input
                    id="basic"
                    type="radio"
                    name="radio-buttons"
                    className="sr-only peer"
                    onChange={onchangePlan}
                    required={true}
                  />
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>

                      <span className="ml-3">Plan basic </span>
                    </div>
                    <ul className="mt-4 list-disc list-inside">
                      <li>Burofax: No incluido</li>
                      <li>Llamadas robotizadas: 5 intentos</li>
                      <li>SMS: 10 mensajes</li>
                      <li>Emails certificados: 20 correos</li>
                      <li>Llamadas directas: 5 llamadas</li>
                    </ul>
                    <span className="italic text-center bg-gradient-to-r from-green-300 via-green-500 to-green-700 text-white px-2 py-1 rounded mt-4">
                      (25 € + IVA y el 20 % del importe recuperado)
                    </span>
                  </div>
                  <div
                    className="absolute inset-0 rounded pointer-events-none"
                    aria-hidden="true"
                  ></div>
                </label>
              </div>

              {/* plan 2 */}

              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className={`block cursor-pointer bg-white text-sm font-medium text-gray-800 p-4 rounded border ${
                    plan === "premium"
                      ? "bg-blue-100 border-blue-400"
                      : "border-gray-200"
                  } hover:border-gray-300 shadow-sm duration-150 ease-in-out w-full relative`}
                >
                  <input
                    id="premium"
                    type="radio"
                    name="radio-buttons"
                    className="sr-only peer"
                    onChange={onchangePlan}
                    required={true}
                  />
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                        />
                      </svg>

                      <span className="ml-3">Plan Premium</span>
                    </div>
                    <ul className="mt-4 list-disc list-inside">
                      <li>Burofax: 1 certificado</li>
                      <li>Llamadas robotizadas: 10 intentos</li>
                      <li>SMS: 20 mensajes</li>
                      <li>Emails certificados: 50 correos</li>
                      <li>Llamadas directas: 10 llamadas</li>
                    </ul>
                    <span className="italic text-center bg-gradient-to-r from-green-300 via-green-500 to-green-700 text-white px-2 py-1 rounded mt-4">
                      (45 € + IVA y el 20 % del importe recuperado)
                    </span>
                  </div>
                  <div
                    className="absolute inset-0 rounded pointer-events-none"
                    aria-hidden="true"
                  ></div>
                </label>
              </div>
            </div>

            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Confirmar encargo
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default CreateExpedient;
