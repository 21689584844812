function HeroHome() {
  return (
    <section className="relative mt-2">
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1 mt-2"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-6">
        {/* Hero content */}
        <div>
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16 mt-4">
            {/* Add a wrapper div with flexbox styles */}
            <div className="flex flex-col justify-center items-center min-h-[100vh] sm:min-h-[75vh]">
              <h1
                className="text-4xl sm:text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
                data-aos="zoom-y-out mt-4"
              >
                  RECUPERACIÓN DE IMPAGOS<br />
                <span className="bg-clip-text text-transparent bg-gradient-to-b from-blue-500 to-teal-200">
                Unifica, Organiza y Recupera
                </span>
              </h1>

              <div className="max-w-3xl mx-auto mt-4">
                <p
                  className="text-xl text-gray-600 mb-8"
                  data-aos="zoom-y-out"
                  data-aos-delay="150"
                >
                  Gestiona tus deudas con nuestro sistema de recobro único,
                  automatizado y centralizado, diseñado especialmente para
                  llevar un seguimiento eficiente.
                </p>
                <div
                  className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                  data-aos="zoom-y-out"
                  data-aos-delay="300"
                >
                  <div>
                    <a
                      className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                      href="/signup"
                    >
                      Empieza a recuperar tus deudas
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
