import React, { useState, useEffect } from "react";
import { baseAPI } from "../config";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const GenerateLinkStripeToDebtor = () => {
  const { id } = useParams();
  const [urlStripe, setUrlStripe] = useState("");

  useEffect(() => {
    if (!id) {
      console.error("Expedient ID is not available");
      return;
    }
    let source = "not defined";
    const currentUrl = window.location.href;

    if (currentUrl.includes("sms")) {
      source = "sms";
    } else if (currentUrl.includes("email")) {
      source = "email";
    } else if (currentUrl.includes("chat")) {
      source = "chat";
    }

    const fetchExpedient = async () => {
      try {
        const response = await fetch(`${baseAPI}/actions/create-url-stripe`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            expedientId: id,
            source: source,
          }),
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Error fetching data");
        }
        setUrlStripe(data.stripeUrl);
      } catch (error) {
        console.error("Error fetching expedient:", error);
      }
    };
    fetchExpedient();
  }, [id]);

  return (
    <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
      <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <h3 className="font-bold text-2xl text-center">
            Realiza tu Pago de Forma Segura
          </h3>
          <p className="text-gray-600 pt-4">
            Estás a solo un paso de resolver tu expediente. Te explicamos cómo
            funciona el proceso:
          </p>
          <ul className="list-disc list-inside text-gray-600 text-s pt-2">
            <li>
              El pago se realiza a través de Stripe, garantizando seguridad y
              confidencialidad.
            </li>
            <li>
              Puedes pagar con tarjeta de crédito, Apple Pay o Google Pay.
            </li>
            <li>
              Una vez realizado el pago, la deuda quedará completamente saldada.
            </li>
            <li>
              Recibirás un email de confirmación, asegurando que no habrá
              futuras reclamaciones por este concepto.
            </li>
            <li>
              Si prefieres utilizar otro medio de pago, por favor contacta con
              nosotros en{" "}
              <a
                href="mailto:legal@claimup.io"
                className="text-blue-600 hover:text-blue-800"
              >
                legal@claimup.io
              </a>
              , o bien a través de nuestro chat: &nbsp;
              <Link
                to={`/chat-debtor/${id}`}
                className="text-blue-600 hover:text-blue-800"
              >
                www.claimup.io/chat
              </Link>
            </li>
          </ul>
          <p className="text-gray-600 pt-4">
            Haz clic en el botón de abajo para proceder con el pago seguro.
          </p>

          {urlStripe && (
            <div className="text-center mt-6">
              <a
                href={urlStripe}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg text-lg transition duration-300 ease-in-out"
              >
                Realizar Pago Ahora
              </a>
            </div>
          )}
        </section>
      </main>
      <footer className="max-w-lg mx-auto flex justify-center text-white">
        <a href="https://www.claimup.io" className="hover:underline">
          www.claimup.io
        </a>
      </footer>
    </div>
  );
};

export default GenerateLinkStripeToDebtor;
