import React, { Component } from "react";

export default class SuccesPaymentDeptor extends Component {


  render() {
    return (
      <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
        <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
          <section>               
            <h3 className="font-bold text-2xl">Enhorabuena, tu pago ha sido confirmado</h3>
            <p className="text-gray-600 pt-2">
              {" "}
              Te confirmamos que tu pago ha sido realizado con éxito. En breve recibirás un correo electrónico con la confirmación de tu pago así como el detalle del mismo. 🚀
            </p>
          </section>
        </main>
        <footer className="max-w-lg mx-auto flex justify-center text-white">
          <a href="www.claimup.io" className="hover:underline">
            www.claimup.io
          </a>
        </footer>
      </div>
    );
  }
}
