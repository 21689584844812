import React, { useState, useEffect } from 'react';
import MessagesBody from '../partials/messages/MessagesBody';
import MessagesFooter from '../partials/messages/MessagesFooter';
import { baseAPI } from '../config';
import { useParams } from "react-router-dom";


function ChatDebtor(props ) {
  const { id } = useParams();
  const [expedientId] = useState(id);
  const [historyChat, setHistoryChat] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [assistantTyping, setAssistantTyping] = useState(false);

  // Fetch chat history
  useEffect(() => {
    console.log('expedientId:', expedientId);
    const fetchChatHistory = async () => {
      try {
        setLoading(true);
        console.log('id:', expedientId);
        const response = await fetch(`${baseAPI}/actions/chathistory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ expedientId }),
        });
        const data = await response.json();
        console.log('data:', data.messages);
        setHistoryChat(data);
      } catch (error) {
        console.error('Error fetching chat history:', error);
        setError('Error fetching chat history');
      } finally {
        setLoading(false);
      }
    };

    if (expedientId) {
      fetchChatHistory();
    }
  }, [expedientId]);

  useEffect(() => {
    if (id) {
     console.log('id:', id);
    }
  }, [id]);

  const handleSendMessage = async (message) => {
    try {
      setLoading(true);
      const messageObject = {
        role: 'user',
        content: message,
        createdAt: new Date().toISOString(),
      };

      setHistoryChat(historyChat => [...historyChat, messageObject]);

      setAssistantTyping(true);
  
      const response = await fetch(`${baseAPI}/actions/new-chat-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ expedientId, userMessage: message }),
      });
      const newMessage = await response.json();
      console.log('newMessage:', newMessage);

      // wait from 1 to 5 seconds random time to simulate assistant typing
      const randomTime = Math.floor(Math.random() * 10) + 3;
      setTimeout(() => {
        setAssistantTyping(false);
        setHistoryChat(historyChat => [...historyChat, newMessage]);
      }, randomTime * 1000);
  
      // Update chat history once with both user message and new message from backend
      
      console.log('historyChat:', historyChat);
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Error sending message');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-col mb-5">
      {/* <HeaderLanding /> */}
      <main className="flex flex-col flex-grow mt-5">
        <div className="flex-grow overflow-auto">
          <MessagesBody historyChat={historyChat}
            assistantTyping={assistantTyping}  
          />
        </div>
        <MessagesFooter 
          onSendMessage={handleSendMessage} 
          loading={loading} 
        />
      </main>
    </div>
  );
}

export default ChatDebtor;