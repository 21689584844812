// InteractionsExpedient.jsx
import React, { Component, useState, useRef } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { baseAPI } from "../../config";

// Componente Funcional InteractionRow
const InteractionRow = ({ interaction }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Si el tipo de interacción es "email_opened", limpiamos los detalles
  if (interaction.type === "email_opened") {
    interaction.details = {};
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formattedDate = new Date(interaction.date).toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const emoJiDirection = interaction.direction === "outgoing" ? "📤" : "🚀";

  return (
    <>
      {/* Fila Principal */}
      <tr
        className={
          interaction.direction === "outgoing" ? "bg-blue-100" : "bg-indigo-200"
        }
      >
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="text-sm text-gray-800">
            {interaction.type}{" "}
            {(interaction.details?.message ||
              interaction.details?.recording_url) && (
              <button
                onClick={toggleExpand}
                className="text-sm font-semibold text-gray-800 ml-2"
                aria-label={
                  isExpanded ? "Contraer detalles" : "Expandir detalles"
                }
              >
                {isExpanded ? "🔽" : "🔼"}
              </button>
            )}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="text-xs text-gray-600">{formattedDate}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3">
          <div className="text-sm text-gray-800">
            {interaction.direction === "outgoing" ? "Outgoing" : "Incoming"}{" "}
            {emoJiDirection}
          </div>
        </td>
      </tr>

      {/* Fila Expandida */}
      {isExpanded && (
        <tr className="bg-blue-50">
          <td colSpan="3" className="px-2 first:pl-5 last:pr-5 py-3">
            <div className="text-sm text-gray-700">
              {/* Mostrar el mensaje si existe */}
              {interaction.details?.message ? (
                <p className="mb-2">{interaction.details.message}</p>
              ) : null}

              {/* Mostrar el reproductor de audio personalizado si existe la URL de grabación */}
              {interaction.details?.recording_url && (
                <div className="flex items-center mt-2">
                  <button
                    onClick={togglePlay}
                    className={`mr-2 text-sm font-semibold ${
                      isPlaying ? "text-red-500" : "text-green-500"
                    }`}
                    aria-label={
                      isPlaying ? "Pausar grabación" : "Reproducir grabación"
                    }
                  >
                    {isPlaying ? "⏸️" : "▶️"}
                  </button>
                  <audio
                    ref={audioRef}
                    src={interaction.details.recording_url}
                    onEnded={() => setIsPlaying(false)}
                    className="hidden"
                  />
                  <span
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() => {
                      audioRef.current.play();
                      setIsPlaying(true);
                    }}
                  >
                    Escuchar Grabación
                  </span>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

// Componente de Clase InteractionsExpedient
export default class InteractionsExpedient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expedientid: props.idExpedient,
      interactions: [],
      anotacion: "",
      fecha: new Date(),
      user_id: "",
      usuario: {},
    };
  }

  componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      try {
        const decoded = jwt_decode(userToken);
        this.setState({
          usuario: decoded,
          user_id: decoded.id,
        });
        this.loadInteractions();
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }

  loadInteractions() {
    axios
      .get(`${baseAPI}/interactions/${this.state.expedientid}`)
      .then((response) => {
        // Ordenar las interacciones por fecha descendente
        response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        this.setState({ interactions: response.data });
        console.log("Interactions:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching interactions:", error);
      });
  }

  render() {
    const { interactions } = this.state;
    return (
      <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
        <header className="px-5 py-4">
          <h2 className="font-semibold text-slate-800">
            Interacciones{" "}
            <span className="text-slate-400 font-medium">
              {interactions.length}
            </span>
          </h2>
        </header>
        <div className="overflow-x-auto">
          <table className="table-auto w-full divide-y divide-slate-200">
            <thead className="text-xs uppercase text-slate-500 bg-slate-50 border-t border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                  Tipo
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                  Fecha
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 text-left">
                  Dirección
                </th>
              </tr>
            </thead>

            <tbody>
              {interactions.map((interaction) => (
                <InteractionRow
                  key={interaction._id}
                  interaction={interaction}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
