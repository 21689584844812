import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../config";
import CurrencyFormat from "react-currency-format";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import WelcomeBanner from "../partials/components/WelcomeBanner";
import moment from "moment";
import jwt_decode from "jwt-decode";

const RecoveredAmountRow = ({ amount }) => (
  <tr>
    <td className="px-3 py-2 whitespace-nowrap">
      <div className="text-left text-gray-800">
        {amount.expedient_id}
      </div>
    </td>
    <td className="px-3 py-2 whitespace-nowrap">
      <div className="text-left text-gray-800">
        {amount.client_name}
      </div>
    </td>
    <td className="px-3 py-2 whitespace-nowrap">
      <div className="text-left text-gray-800">
        {moment(amount.date).format("DD/MM/YYYY")}
      </div>
    </td>
    <td className="px-3 py-2 whitespace-nowrap">
      <div className="text-left text-gray-800">
        <CurrencyFormat
          value={amount.recovered_amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          suffix={" €"}
          decimalScale={2}
        />
      </div>
    </td>
    <td className="px-3 py-2 whitespace-nowrap">
      <div className="text-left text-gray-800">
        {amount.client_internal_reference}
      </div>
    </td>
  </tr>
);

const RecoveredAmountList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [recoveredAmounts, setRecoveredAmounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const userToken = localStorage.getItem("token");
  if (!userToken) window.location = "/login";

  const decoded = jwt_decode(userToken);
  const userRole = decoded.role;

  useEffect(() => {
    // todo: los filtros de fecha y user_id no están funcionando
    const fetchRecoveredAmounts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseAPI}/expedients/recovered-amounts`, {
          headers: { Authorization: `Bearer ${userToken}` },
          params: {
            user_id: userId,
            startDate: startDate ? moment(startDate).toISOString() : undefined,
            endDate: endDate ? moment(endDate).toISOString() : undefined,
            role: userRole,
          },
        });
        setRecoveredAmounts(response.data);
      } catch (error) {
        console.error("Error fetching recovered amounts", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecoveredAmounts();
  }, [userId, startDate, endDate, userToken, userRole]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-6 w-full max-w-9xl mx-auto">
            <WelcomeBanner />

            <div className="mb-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Filtrar por User ID
                </label>
                <input
                  type="text"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  placeholder="Ingrese el User ID"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Fecha Inicio
                </label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Fecha Fin
                </label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                />
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4 mt-4">
              {loading ? (
                <p className="text-center col-span-12">Cargando...</p>
              ) : (
                <div className="col-span-12">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full text-left">
                      <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
                        <tr>
                          <th className="px-3 py-2">Expedient ID</th>
                          <th className="px-3 py-2">Usuario</th>
                          <th className="px-3 py-2">Fecha</th>
                          <th className="px-3 py-2">Importe Recuperado (€)</th>
                          <th className="px-3 py-2">Contrato</th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-200">
                        {recoveredAmounts.map((amount) => (
                          <RecoveredAmountRow
                            key={amount._id}
                            amount={amount}
                          />
                        ))}
                      </tbody>
                    </table>
                    {recoveredAmounts.length === 0 && (
                      <div className="text-center text-gray-400 py-6">
                        No hay importes recuperados en el rango seleccionado.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default RecoveredAmountList;
