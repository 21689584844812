import React, { Component } from "react";
import axios from "axios";
import { baseAPI } from "../config";

class CompleteUserProfile extends Component {
  constructor(props) {
    super(props);

    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeUserLastname = this.onChangeUserLastname.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      username: "",
      userlastname: "",
      userid: props.match.params.id,
    };
  }

  onChangeUserName = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  onChangeUserLastname = (e) => {
    this.setState({
      userlastname: e.target.value,
    });
  };

  onSubmit(e) {
    e.preventDefault();

    const user = {
      username: this.state.username,
      userlastname: this.state.userlastname,
      userid: this.state.userid,
    };

    axios
      .post(`${baseAPI}/users/updateUserProfile`, user)
      .then((response) => {
        let token = response.data.token;
        localStorage.setItem("token", token);
        this.props.history.push("/dashboard"); // Redirige al usuario a "/dashboard"
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
        <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
          <section>
            <h3 className="font-bold text-2xl text-center ">
              Completa tu perfil
            </h3>
          </section>

          <section className="mt-10">
            <form
              className="flex flex-col"
              method="POST"
              action="#"
              onSubmit={this.onSubmit}
            >
              <div className="mb-6 pt-3 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                  htmlFor="email"
                >
                  Nombre
                </label>
                <input
                  onChange={this.onChangeUserName}
                  type="text"
                  id="name"
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                />
              </div>
              <div className="mb-6 pt-3 rounded ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                  htmlFor="email"
                >
                  Apellidos
                </label>
                <input
                  onChange={this.onChangeUserLastname}
                  type="text"
                  id="lastname"
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                />
              </div>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
              >
                Confirmar
              </button>
            </form>
          </section>
        </main>

        <footer className="max-w-lg mx-auto flex justify-center text-white">
          <a href="www.claimup.io" className="hover:underline">
            www.claimup.io
          </a>
        </footer>
      </div>
    );
  }
}

export default CompleteUserProfile;
