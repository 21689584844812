import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const UserSelect = ({ selectedUser, onUserChange }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${baseAPI}/users/list`);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Error al cargar la lista de usuarios.");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="mb-5">
      <label
        htmlFor="userSelect"
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
      >
        Seleccionar Usuario:
      </label>
      {loading ? (
        <p>Cargando usuarios...</p>
      ) : (
        <select
          id="userSelect"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          value={selectedUser}
          onChange={(e) => onUserChange(e.target.value)}
        >
          <option value="">Todos los Usuarios</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.username} {user.userlastname} ({user.usermail})
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default UserSelect;
