import React, { useRef, useEffect } from "react";
import User01 from "../../images/claimup-chat.png";
import User02 from "../../images/claim-up-16.png";

function MessagesBody({ historyChat, assistantTyping }) {
  const messagesEndRef = useRef(null);

  // Helper function to format dates for messages
  const formatMessageDate = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  // Function to format date for displaying as separator
  const formatDateSeparator = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Group messages by date
  const messagesByDate = historyChat.reduce((acc, message) => {
    if (message.role === "system") {
      return acc;
    }

    // Extrae solo la fecha sin la hora
    const date = message.createdAt
      ? new Date(message.createdAt).toISOString().split("T")[0]
      : "Fecha desconocida";

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});

  const maskURLs = (text) => {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    return text.replace(urlRegex, '<a href="$1" target="_blank" style="background-color: green; color: white; padding: 2px 4px; border-radius: 4px;">Clica aquí para pagar</a>');
  };
  
  // Scroll to the bottom whenever historyChat changes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [historyChat]);

  return (
    <div className="grow px-4 sm:px-6 md:px-5 py-6">
      {Object.entries(messagesByDate).map(([date, messages], index) => (
        <React.Fragment key={index}>
          {/* Date separator */}
          <div className="flex justify-center">
            <div className="inline-flex items-center justify-center text-xs font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full my-5">
              {formatDateSeparator(date)}
            </div>
          </div>
          {/* Messages for the date */}
          {messages.map((chat, msgIndex) => (
            <div key={msgIndex} className="flex items-start mb-4 last:mb-0">
              <img
                className="rounded-full mr-4"
                src={chat.role === "assistant" ? User01 : User02}
                width="40"
                height="40"
                alt="User"
              />
              <div>
              <div
                  className={`text-sm ${
                    chat.role === "assistant"
                      ? "bg-white text-slate-800"
                      : "bg-indigo-500 text-white"
                  } p-3 rounded-lg border ${
                    chat.role === "assistant"
                      ? "border-slate-200"
                      : "border-transparent"
                  } shadow-md mb-1 max-w-xl break-words`}
                  dangerouslySetInnerHTML={{ __html: maskURLs(chat.content) }} // Cambio clave aquí
                ></div>
                <div className="flex items-center justify-between">
                  <div className="text-xs text-slate-500 font-medium">
                    {formatMessageDate(chat.createdAt)}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* Scroll to bottom reference */}
          <div ref={messagesEndRef} />
        </React.Fragment>
      ))}
      {assistantTyping && (
        <div className="flex items-start mb-4 last:mb-0">
          <img
            className="rounded-full mr-4"
            src={User01}
            width="40"
            height="40"
            alt="User 01"
          />
          <div>
            <div className="text-sm bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
              <svg
                className="fill-current text-slate-400"
                viewBox="0 0 15 3"
                width="15"
                height="3"
              >
                <circle cx="1.5" cy="1.5" r="1.5">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"
                  />
                </circle>
                <circle cx="7.5" cy="1.5" r="1.5">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2"
                  />
                </circle>
                <circle cx="13.5" cy="1.5" r="1.5">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3"
                  />
                </circle>
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MessagesBody;
