import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import UserSelect from "./userSelectList";

const getStatusStyle = (status) => {
  switch (status) {
    case "On going":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Email opened":
      return "bg-green-100 text-green-800"; // Positivo
    case "Hot":
      return "bg-red-100 text-red-800"; // Negativo
    case "Cold":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Fallido":
      return "bg-red-100 text-red-800"; // Negativo
    case "Ilocalizable":
      return "bg-red-100 text-red-800"; // Negativo
    case "Sin datos de contacto":
      return "bg-red-100 text-red-800"; // Negativo
    case "Cancelled":
      return "bg-red-100 text-red-800"; // Negativo
    case "Dispute":
      return "bg-red-100 text-red-800"; // Negativo
    case "Burofax Enviado":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Burofax con resultado negativo":
      return "bg-red-100 text-red-800"; // Negativo
    case "Burofax con resultado positivo":
      return "bg-green-100 text-green-800"; // Positivo
    case "Demanda pendiente":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Demanda preparada":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Demanda presentada":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Demanda Admitida":
      return "bg-green-100 text-green-800"; // Positivo
    case "Demanda No Admitida":
      return "bg-red-100 text-red-800"; // Negativo
    case "Oposición del Deudor":
      return "bg-red-100 text-red-800"; // Negativo
    case "Pendiente Requerimiento":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Requerimiento Judicial Positivo":
      return "bg-green-100 text-green-800"; // Positivo
    case "Pendiente Ejecucion":
      return "bg-red-100 text-red-800"; // Negativo
    case "Sentencia Estimatoria":
      return "bg-green-100 text-green-800"; // Positivo
    case "Sentencia Desestimatoria":
      return "bg-red-100 text-red-800"; // Negativo
    case "Sentencia Parcial":
      return "bg-yellow-100 text-yellow-800"; // Neutro
    case "Cobrado":
      return "bg-green-100 text-green-800"; // Positivo
    case "Cobrado Judicialmente":
      return "bg-green-100 text-green-800"; // Positivo
    default:
      return "bg-gray-100 text-gray-800"; // Neutro para casos no previstos
  }
};

const CurrencyFormatComponent = ({ value }) => (
  <CurrencyFormat
    value={value}
    displayType={"text"}
    thousandSeparator={"."}
    decimalSeparator={","}
    suffix={" €"}
    decimalScale={2}
  />
);

const Expedients = ({
  createdAt,
  expedient,
  viewExpedient,
  deleteExpedient,
  role,
}) => {
  const { user_id, deudores_id, importe_deuda, estado, _id } = expedient;
  const formattedDate = createdAt
    ? moment(createdAt).format("DD/MM/YYYY")
    : "No Date";
  return (
    <tr>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">{formattedDate}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">
            {user_id.usermail ? (
              user_id.usermail
            ) : (
              <span role="img" aria-label="no mail">
                ❌
              </span>
            )}
          </div>
        </div>
      </td>

      <td className="p-2 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-gray-800">
            {deudores_id[0].debtorname}
          </div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <div className="text-left font-medium">
          <CurrencyFormatComponent value={importe_deuda} />
        </div>
      </td>
      <td className="p-2 whitespace-nowrap">
        <span
          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusStyle(
            estado
          )}`}
        >
          {estado}
        </span>
      </td>
      <td className="p-2 whitespace-nowrap flex space-x-2">
        <a href={`/view/${_id}`} target="_blank" rel="noreferrer">
          🔍
        </a>
        {role === "admin" && (
          <button
            aria-label="Eliminar expediente"
            className="focus:outline-none"
            onClick={() => deleteExpedient(_id)}
          >
            🗑️
          </button>
        )}
      </td>
    </tr>
  );
};

const ExpedientsAdmin = ({ searchText }) => {
  const [expedients, setExpedients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 20;
  const [role, setRole] = useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedUser, setSelectedUser] = useState(""); // Nuevo estado para el usuario seleccionado
  const [showFilter, setShowFilter] = useState(false);
  const [sortField] = useState("importe_deuda");
  const [sortOrder, setSortOrder] = useState("desc");

  const exportToExcel = async () => {
    try {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        toast.error("No estás autenticado.", {
          position: "top-right",
          autoClose: 5000,
        });
        return;
      }

      const decoded = jwt_decode(userToken);

      const params = {
        decoded: JSON.stringify(decoded),
        estado: selectedEstado,
        cliente: selectedUser, // Cambiado 'selectedCliente' por 'selectedUser'
        search: searchText,
        sortField,
        sortOrder,
      };

      const response = await axios.get(`${baseAPI}/expedients/export`, {
        params,
        responseType: "blob", // Importante para manejar archivos binarios
      });

      // Crear un blob a partir de la respuesta
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Usar file-saver para guardar el archivo
      saveAs(blob, "expedients.xlsx");

      toast.success("Exportación exitosa!", {
        position: "top-right",
        autoClose: 5000,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error al exportar los expedientes.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage); // Esto debería actualizar la página actual
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    const fetchExpedients = async () => {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        console.log("no hay token");
        return;
      }

      const decoded = jwt_decode(userToken);
      setRole(decoded.role);

      try {
        const response = await axios.get(`${baseAPI}/expedients`, {
          params: {
            decoded: JSON.stringify(decoded),
            page: currentPage,
            limit: itemsPerPage,
            search: searchText,
            estado: selectedEstado,
            cliente: selectedUser, // Pasar el usuario seleccionado como cliente
            sortField,
            sortOrder,
          },
        });
        setExpedients(response.data.expedients);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.log(error);
      }
    };

    fetchExpedients();
  }, [
    currentPage,
    searchText,
    selectedEstado,
    selectedUser, // Añadir dependencia del usuario seleccionado
    itemsPerPage,
    sortField,
    sortOrder,
  ]);

  function Pagination({ currentPage, totalPages, onPageChange }) {
    return (
      <div className="flex justify-center items-center mt-4">
        {/* Botón Anterior */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <span className="sr-only">Previous</span>
          <span className="text-lg">&larr;</span>
        </button>

        {/* Indicador de Página */}
        <span className="mx-2 text-sm text-gray-700">
          {currentPage} / {totalPages}
        </span>

        {/* Botón Siguiente */}
        <button
          className={`flex items-center justify-center px-3 py-2 rounded-md text-sm font-medium text-indigo-500 hover:text-indigo-600 focus:outline-none ${
            currentPage === totalPages && "opacity-50 cursor-not-allowed"
          }`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <span className="text-lg">&rarr;</span>
          <span className="sr-only">Next</span>
        </button>
      </div>
    );
  }

  const deleteExpedient = async (id) => {
    var confirmacion = window.confirm(
      "¿Estás seguro de que quieres eliminar este expediente?"
    );
    if (confirmacion) {
      try {
        const response = await axios.delete(`${baseAPI}/expedients/${id}`);
        if (response.status === 200) {
          toast.success("Expediente eliminado! 😄 ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setExpedients(expedients.filter((expedient) => expedient._id !== id));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const viewExpedient = (id) => {
    window.location = `/view/${id}`;
  };

  return (
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex justify-between items-center">
        <h2 className="font-semibold text-gray-800">Reclamaciones</h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setShowFilter(!showFilter)}
            className="flex items-center text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            {showFilter ? (
              <>
                Ocultar Filtros <span className="ml-2">🗂️</span>
              </>
            ) : (
              <>
                Mostrar Filtros <span className="ml-2">🗂️</span>
              </>
            )}
          </button>
          {/* Nuevo Botón de Exportación */}
          <button
            onClick={exportToExcel}
            className="flex items-center px-4 py-2 bg-blue-200 text-white rounded-md text-sm hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            📥
          </button>
        </div>
      </header>

      <div className="p-3">
        {/* FILTRO */}
        {/* Selector de Estado */}

        {showFilter && (
          <div className="mb-5">
            <label
              htmlFor="estadoFilter"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
            >
              Filtrar por estado:
            </label>
            <select
              id="estadoFilter"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              value={selectedEstado}
              onChange={(e) => setSelectedEstado(e.target.value)}
            >
              <option value="">Todos los Estados</option>
              <option value="On going">On going</option>
              <option value="Email opened">Email opened</option>
              <option value="Bounced">Bounced</option>
              <option value="Hot">Hot</option>
              <option value="Cold">Cold</option>
              <option value="Fallido">Fallido</option>
              <option value="Ilocalizable">Ilocalizable</option>
              <option value="Sin datos de contacto">
                Sin datos de Contacto
              </option>
              <option value="Cancelled">Cancelled</option>
              <option value="Dispute">Dispute</option>
              <option value="Burofax Enviado">Burofax Enviado</option>
              <option value="Burofax con resultado negativo">
                Burofax con resultado negativo
              </option>
              <option value="Burofax con resultado positivo">
                Burofax con resultado positivo
              </option>
              <option value="Demanda pendiente">Demanda pendiente</option>
              <option value="Demanda preparada">Demanda preparada</option>
              <option value="Demanda presentada">Demanda presentada</option>
              <option value="Demanda Admitida">Demanda Admitida</option>
              <option value="Demanda No Admitida">Demanda No Admitida</option>
              <option value="Oposición del Deudor">Oposición del Deudor</option>
              <option value="Pendiente Requerimiento">
                Pendiente Requerimiento
              </option>
              <option value="Requerimiento Judicial Positivo">
                Requerimiento Judicial Positivo
              </option>
              <option value="Pendiente Ejecucion">Pendiente Ejecucion</option>
              <option value="Sentencia Estimatoria">
                Sentencia Estimatoria
              </option>
              <option value="Sentencia Desestimatoria">
                Sentencia Desestimatoria
              </option>
              <option value="Sentencia Parcial">Sentencia Parcial</option>
              <option value="Cobrado">Cobrado</option>
              <option value="Cobrado Judicialmente">
                Cobrado Judicialmente
              </option>
            </select>
            {role === "admin" && (
              <>
                <UserSelect
                  selectedUser={selectedUser}
                  onUserChange={(user) => setSelectedUser(user)}
                />
              </>
            )}
          </div>
        )}

        {/* Aquí continúa el resto de tu código como la tabla... */}

        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
              <tr>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Fecha Creación</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Cliente</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Deudor</div>
                </th>
                <th
                  onClick={toggleSortOrder}
                  className="p-4 whitespace-nowrap cursor-pointer"
                >
                  <div className="font-semibold text-left flex items-center">
                    Importe Deuda{" "}
                    <span className="ml-2">
                      {sortOrder === "asc" ? "🔼" : "🔽"}
                    </span>
                  </div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Estado</div>
                </th>
                <th className="p-4 whitespace-nowrap">
                  <div className="font-semibold text-left">Acciones</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {expedients.map((expedient) => (
                <Expedients
                  createdAt={expedient.createdAt}
                  expedient={expedient}
                  deleteExpedient={deleteExpedient}
                  viewExpedient={viewExpedient}
                  key={expedient._id}
                  role={role}
                />
              ))}
            </tbody>
          </table>
          {expedients.length === 0 && (
            <div className="text-center text-gray-400 py-8">
              No hay expedientes. !Crea uno nuevo!
            </div>
          )}
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpedientsAdmin;
