import React, { useState } from 'react';

function MessagesFooter({ onSendMessage, loading }) {
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    const messageToSend = message
    setMessage(''); // Clear the input field after sending the message

    // Call the onSendMessage function with the new message
    await onSendMessage(messageToSend);
    setMessage(''); // Clear the input field after sending the message
  };


  return (
    <div className="sticky bottom-0 w-full">
      <div className="flex items-center justify-between bg-white border-t border-slate-200 px-4 sm:px-6 md:px-5 h-16">
        <form className="flex w-full" onSubmit={handleSubmit}>
          <div className="flex-grow mr-3">
            <label htmlFor="message-input" className="sr-only">Type a message</label>
            <input 
              id="message-input" 
              className="form-input w-full bg-slate-100 border-transparent focus:bg-white focus:border-slate-300" 
              type="text" 
              placeholder="Aa" 
              value={message} 
              onChange={(e) => setMessage(e.target.value)} 
              disabled={loading}
            />
          </div>
          <button 
            type="submit" 
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap" 
            disabled={loading}
          >
            Send -&gt;
          </button>
        </form>
      </div>
    </div>
  );
}

export default MessagesFooter;
