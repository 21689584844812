import React from 'react';

import HeaderLanding from '../partials/HeaderLanding';
import HeroHome from '../partials/HeroHome';
// import FeaturesHome from '../partials/FeaturesHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import Pricing from '../partials/Pricing';
import NewFeatures from '../partials/NewFeatures';


function Home() {
  return (
    // add meta tags seo
    
    <div className="flex flex-col min-h-screen overflow-hidden">
      

      {/*  Site header */}
      <HeaderLanding />

      {/*  Page content */}
      <main className="grow">

        {/*  Page sections */}
        <HeroHome />
        {/* <FeaturesHome /> */}
        <NewFeatures />
        <FeaturesBlocks />
        
        <Pricing />
        
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;