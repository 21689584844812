import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../../config";
import DashboardCard09 from "../components/DashboardCard09";
import AnalyticsCard06 from "../components/AnalyticsCard06";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import UserSelect from "./userSelectList";

const DataDashboard = () => {
  const [totals, setTotals] = useState({
    totalReclamaciones: 0,
    totalDeuda: 0,
    totalRecuperado: 0,
    totalPendiente: 0,
    importesRecuperadosPorDia: [],
    estadosAgrupados: [],
  });
  const [filterUser, setFilterUser] = useState("");
  const [decoded, setDecoded] = useState({});

  // Nuevos estados para las fechas (strings en formato YYYY-MM-DD)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Estado para mostrar/ocultar filtros
  const [showFilters, setShowFilters] = useState(false);

  const fetchData = useCallback(async () => {
    const userToken = localStorage.getItem("token");
    if (!userToken) return;

    const decodedData = jwt_decode(userToken);
    setDecoded(decodedData);

    try {
      const params = {
        decoded: JSON.stringify(decodedData),
        filterUser,
      };

      if (startDate) {
        params.startDate = new Date(startDate).toISOString();
      }
      if (endDate) {
        params.endDate = new Date(endDate).toISOString();
      }

      const { data } = await axios.get(`${baseAPI}/expedients/dashboard`, {
        params,
      });
      const calculatedTotals = calculateTotals(data);
      setTotals(calculatedTotals);
    } catch (error) {
      console.error(error);
    }
  }, [filterUser, startDate, endDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const chartData = {
    labels: totals.importesRecuperadosPorDia.map((item) => item.fecha),
    datasets: [
      {
        label: "Importes Recuperados",
        data: totals.importesRecuperadosPorDia.map((item) => item.importe),
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  const calculateTotals = (expedientes) => {
    const sumaLasDeudas = Math.round(
      expedientes.reduce((acc, { importe_deuda }) => acc + importe_deuda, 0)
    );
    const filtraImportesRecuperados = expedientes.flatMap(
      ({ importes_recuperados }) => importes_recuperados
    );

    const sumaImportesRecuperados = Math.round(
      filtraImportesRecuperados.reduce((acc, { importe }) => acc + importe, 0)
    );

    let agruparImportesRecuperadosPorDia = [];
    let importesPorDia = new Map();

    filtraImportesRecuperados.forEach((item) => {
      let fechaSinHora = new Date(item.fecha);
      fechaSinHora.setHours(0, 0, 0, 0);
      let key = fechaSinHora.toISOString().split("T")[0]; // Clave como YYYY-MM-DD

      if (!importesPorDia.has(key)) {
        importesPorDia.set(key, { fecha: key, importe: 0 });
      }
      let acumulado = importesPorDia.get(key);
      acumulado.importe += item.importe;
    });

    agruparImportesRecuperadosPorDia = Array.from(importesPorDia.values());

    // Ordenar por fecha
    agruparImportesRecuperadosPorDia.sort(
      (a, b) => new Date(a.fecha) - new Date(b.fecha)
    );

    let estadosAgrupados = [];
    let estados = new Map();
    expedientes.forEach((item) => {
      let key = item.estado;
      if (!estados.has(key)) {
        estados.set(key, { estado: key, cantidad: 0, totalImporteDeuda: 0 });
      }
      let acumulado = estados.get(key);
      acumulado.cantidad += 1;
      acumulado.totalImporteDeuda += item.importe_deuda; // Suma el importe de deuda al acumulado
    });
    estadosAgrupados = Array.from(estados.values());

    // Ordenar por cantidad
    estadosAgrupados.sort((a, b) => b.cantidad - a.cantidad);

    // Retorna los totales y los importes recuperados por día
    return {
      totalReclamaciones: expedientes.length,
      totalDeuda: sumaLasDeudas,
      totalRecuperado: sumaImportesRecuperados,
      totalPendiente: sumaLasDeudas - sumaImportesRecuperados,
      importesRecuperadosPorDia: agruparImportesRecuperadosPorDia,
      estadosAgrupados: estadosAgrupados,
    };
  };

  const { totalReclamaciones, totalDeuda, totalRecuperado, totalPendiente } =
    totals;

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          {/* Title and Description */}
          <div className="mb-6 lg:mb-0">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Bienvenido <span className="animate-wave">👋</span>
            </h1>
            <p className="text-gray-600 mt-2">
              Monitoriza el estado de tu deuda en tiempo real de manera sencilla
              y efectiva.
            </p>
          </div>

          {/* Toggle Button for Filters */}
          <div className="w-full lg:w-auto">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {showFilters ? "Ocultar Filtros" : "Mostrar Filtros"}
              <span className="ml-2">
                {showFilters ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </span>
            </button>
          </div>
        </div>

        {/* Filters Section - Conditionally Rendered */}
        {showFilters && (
          <div className="mt-6">
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              {decoded.role === "admin" && (
                <div className="flex flex-col sm:flex-row items-start sm:items-center">
                  <UserSelect
                    selectedUser={filterUser}
                    onUserChange={(user) => setFilterUser(user)}
                  />
                </div>
              )}

              {/* Fecha Inicio */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <label
                  className="mb-2 sm:mb-0 sm:mr-2 text-sm font-medium text-gray-700"
                  htmlFor="startDate"
                >
                  Fecha de Creación - Inicio:
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Fecha Fin */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <label
                  className="mb-2 sm:mb-0 sm:mr-2 text-sm font-medium text-gray-700"
                  htmlFor="endDate"
                >
                  Fecha de Creación - Fin:
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {/* Botón Limpiar Fechas */}
              {(startDate || endDate) && (
                <button
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                  }}
                  className="px-4 py-2 bg-red-500 text-white rounded-md text-sm hover:bg-red-600 transition-colors focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Limpiar Fechas
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Gráfico Principal */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <DashboardCard09 chartData={chartData} />
      </div>

      {/* Funnel o Análisis */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <AnalyticsCard06 estadosAgrupados={totals.estadosAgrupados} />
      </div>

      {/* Tarjetas de Totales */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        <DashboardCard
          title="Total Reclamaciones"
          value={totalReclamaciones}
          colorClass="bg-blue-500"
        />
        <DashboardCard
          title="Total Deuda"
          value={totalDeuda}
          formatCurrency
          colorClass="bg-red-500"
        />
        <DashboardCard
          title="Total Recuperado"
          value={totalRecuperado}
          formatCurrency
          colorClass="bg-green-500"
        />
        <DashboardCard
          title="Deuda Pendiente"
          value={totalPendiente}
          formatCurrency
          colorClass="bg-yellow-500"
        />
      </div>
    </div>
  );
};

const DashboardCard = ({
  title,
  value,
  formatCurrency,
  colorClass = "bg-gray-500",
}) => (
  <div className="flex flex-col bg-white shadow-md rounded-lg border border-gray-200 p-4">
    <header className="mb-4">
      <h2 className="text-lg font-semibold text-gray-800">{title}</h2>
    </header>
    <div className="flex items-center">
      <div
        className={`w-3 h-3 rounded-full ${colorClass} mr-3`}
        aria-hidden="true"
      ></div>
      <div className="text-2xl font-bold text-gray-800">
        {formatCurrency ? (
          <CurrencyFormat
            value={value}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={"€"}
          />
        ) : (
          value
        )}
      </div>
    </div>
  </div>
);

export default DataDashboard;
