import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { baseAPI } from "../config";
import { toast } from "react-toastify";
import LogoImage from "../images/claim-up-logo-1.png";

const Login = () => {
  const [usermail, setUsermail] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/expedients");
    }
  }, [history]);

  const onSubmit = (e) => {
    e.preventDefault();

    const user = {
      usermail,
      userpassword,
    };

    axios
      .post(`${baseAPI}/users/login`, user)
      .then((response) => {
        let token = response.data.token;
        localStorage.setItem("token", token);
        axios.defaults.headers.common["Authorization"] = token;
        window.location = "/expedients";
      })
      .catch((error) => {
        console.log(error);
        toast.error("Usuario o contraseña incorrecta", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <body className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
      <main className="bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <section>
            <img
              src={LogoImage}
              width="200"
              height="200"
              alt="Claim Up software de reclamación de deudas"
            />
            <h4 className="font-bold text-1xl text-center mt-5">
              INICIO SESIÓN
            </h4>
          </section>
        </section>

        <section className="mt-10">
          <form
            className="flex flex-col"
            method="POST"
            action="#"
            onSubmit={onSubmit}
          >
            <div className="mb-6 pt-3 rounded ">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="email"
              >
                Email
              </label>
              <input
                onChange={(e) => setUsermail(e.target.value)}
                type="text"
                id="email"
                className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
              />
            </div>
            <div className="mb-6 pt-3 rounded">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 ml-3"
                htmlFor="password"
              >
                Contraseña
              </label>
              <input
                onChange={(e) => setUserpassword(e.target.value)}
                type="password"
                id="password"
                className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
              />
            </div>
            <div className="flex justify-end">
              <Link
                className="text-sm text-blue-600 hover:text-blue-700 hover:underline mb-6"
                to={`/forgot-password`}
              >
                ¿Has olvidado la contraseña?
              </Link>
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Iniciar sesión
            </button>
          </form>
        </section>
      </main>

      <div className="max-w-lg mx-auto text-center mt-12 mb-6">
        <p className="text-white">
          ¿No tienes cuenta?{" "}
          <Link className="font-bold hover:underline" to={`/signup`}>
            Registrate gratis
          </Link>
        </p>
      </div>

      <footer className="max-w-lg mx-auto flex justify-center text-white">
        <a href="www.claimup.io" className="hover:underline">
          www.claimup.io
        </a>
      </footer>
    </body>
  );
};

export default Login;
