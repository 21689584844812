import React, { useRef, useState } from "react";
import S3 from "react-aws-s3";
import axios from "axios";
import { baseAPI } from "../../src/config";
require("dotenv").config();

function UploadFiles(props) {
  var idExp = props.idExpedient;
  const fileInput = useRef();
  const [docName, setDocName] = useState("");
  const handleClick = (event) => {
    event.preventDefault();
    let file = fileInput.current.files[0];
    let newFileName = docName.replace(/\..+$/, "");
    const config = {
      bucketName: "devmiralbell",
      region: "eu-west-3",
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: "Vekb4a2Pjf67Z03bgiCm/38/bbGc6FtHiliXOpKm",
    };
    console.log(config);
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      const infoDoc = {
        url: data.location,
        nombredocumento: data.key,
        uploaddate: new Date(),
      };
      async function postDoc() {
        await axios
          .put(`${baseAPI}/expedients/adddocuments/` + idExp, infoDoc)
          .then((res) => {
            console.log(res.data);
          })
          .catch(function (error) {
            console.log(error);
            alert("No se han subido los documentos");
          });
        return true;
      }
      postDoc().then(() => {
        window.location.reload();
      });
    });
  };
  return (
    <div className="container mt-10 ">
      <form
        className="flex flex-col flex-wrap "
        method="POST"
        onSubmit={handleClick}
      >
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
              htmlFor="grid-last-name"
            >
              Subir documentos
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-grey-darker border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white-500 focus:border-gray-600"
              id="grid-last-name"
              type="file"
              placeholder="subir documentos"
              ref={fileInput}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 mt-2"
              htmlFor="grid-last-name"
            >
              Nombre del documento
            </label>
            <input
              className=" rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
              id="grid-last-name"
              type="text"
              placeholder="contrato, prestamo, burofax..."
              onChange={(e) => setDocName(e.target.value)}
            />
          </div>
        </div>
        <button
          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
          type="submit"
        >
          Subir documentos
        </button>
      </form>
    </div>
  );
}

export default UploadFiles;
