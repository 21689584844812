import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { baseAPI } from "../config";
import LogoImage from "../images/claim-up-logo-1.png";


const Signup = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    usermail: "",
    userpassword: "",
    userphone: "",
    userrole: "client",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const user = {
      usermail: formData.usermail,
      userpassword: formData.userpassword,
      userphone: formData.userphone,
      userrole: formData.userrole,
    };

    const userLogin = {
      usermail: formData.usermail,
      userpassword: formData.userpassword,
    };
    async function createUser(user) {
      const response = await axios.post(`${baseAPI}/users/add`, user);
      return response;
    }

    async function login(userLogin) {
      const res = await axios.post(`${baseAPI}/users/login`, userLogin);
      return res;
    }

    createUser(user).then((response) => {
      console.log(response);
      if (response.status === 400 || response.status === 404) {
        alert("Ha habido un error, contacte con el administrador");
      }
      if (response.status === 200) {
        login(userLogin).then((res) => {
          if (res.status === 200) {
            let token = res.data.token;
            let userid = res.data.userId; // Supongamos que el id del usuario viene en la respuesta.
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = token;
            history.push(`/complete-user-profile/${userid}`);
          }
        });
      }
    });
  };

  return (
    <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
      <main className="bg-white max-w-2xl mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
        <section>
          <img
            src={LogoImage}
            width="200"
            height="200"
            alt="Claim Up software de reclamación de deudas"
          />
          <h4 className="font-bold text-1xl text-center mt-5">
            REGISTRO CLIENTE
          </h4>
        </section>

        <section className="mt-10">
          <form
            className="flex flex-col flex-wrap"
            method="POST"
            action="#"
            onSubmit={onSubmit}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                  htmlFor="grid-last-name"
                >
                  Mail
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  type="email"
                  name="usermail"
                  placeholder="Correo electrónico"
                  onChange={handleChange}
                  value={formData.usermail}
                />
              </div>
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                  htmlFor="grid-first-name"
                >
                  Teléfono
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-first-name"
                  type="text"
                  name="userphone"
                  placeholder="Número"
                  onChange={handleChange}
                  value={formData.userphone}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/1 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                  htmlFor="grid-last-name"
                >
                  Contraseña
                </label>
                <input
                  className="rounded w-full text-gray-700 focus:outline-none border-b-1 border-gray-300 focus:border-blue-600 transition duration-500 px-3 pb-3"
                  id="grid-last-name"
                  type="password"
                  name="userpassword"
                  placeholder="Contraseña"
                  onChange={handleChange}
                  value={formData.userpassword}
                />
              </div>
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
              type="submit"
            >
              Sign Up
            </button>
          </form>
        </section>
      </main>

      <div className="max-w-lg mx-auto text-center mt-12 mb-6">
        <p className="text-white">
          ¿Ya tienes una cuenta?
          <Link className="font-bold hover:underline" to={`/login`}>
            {" "}
            Inicia sesión
          </Link>
        </p>
      </div>

      <footer className="max-w-lg mx-auto flex justify-center text-white">
        <a href="www.claimup.io" className="hover:underline">
          www.claimup.io
        </a>
      </footer>
    </div>
  );
};

export default Signup;
