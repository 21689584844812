import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { baseAPI } from '../../config';
import { toast } from 'react-toastify';

const Note = ({ anotacion, onCompletedTask, activeRole }) => {
  return (
    <div
      className={`shadow-md rounded border p-5 mt-3 ${anotacion.creator_role === 'admin' ? 'bg-blue-100 border-blue-200' : 'bg-indigo-300 border-indigo-400'}`}
    >
      <header className="flex justify-between items-start space-x-3 mb-3">
        <div>
          <div className="leading-tight">
            <span className="text-sm font-semibold text-gray-800">
              {anotacion.creator_name} - {anotacion.creator_role}
              {anotacion.tarea === 'Pendiente' && ' - 👨‍💻'}
              {anotacion.tarea === 'Completada' && ' - ✅'}
            </span>
          </div>
          <div className="text-xs text-gray-600">
            {anotacion.fecha.substring(0, 10)}
          </div>
        </div>
        {anotacion.tarea === 'Pendiente' && anotacion.creator_role !== activeRole && (
          <div className="flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-600"
              onChange={() => onCompletedTask(anotacion._id)}
            />
            <label className="ml-2 text-sm text-gray-800">Completada</label>
          </div>
        )}
      </header>
      <div className="text-sm text-gray-800 space-y-2 mb-5">
        <p>{anotacion.anotacion}</p>
      </div>
    </div>
  );
};

const NotesExpedient = ({ idExpedient }) => {
  const [anotaciones, setAnotaciones] = useState([]);
  const [anotacion, setAnotacion] = useState('');
  const [usuario, setUsuario] = useState({});
  const [tarea, setTarea] = useState('No hay tarea');

  useEffect(() => {
    const fetchData = async () => {
      const userToken = localStorage.getItem('token');
      const decoded = jwt_decode(userToken);
      setUsuario(decoded);

      try {
        const response = await axios.get(`${baseAPI}/annotations/${idExpedient}`);
        setAnotaciones(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [idExpedient]);

  const handleCompletedTask = async (id) => {
    try {
      await axios.put(`${baseAPI}/annotations/completedtask/${id}`);
      toast.success('Tarea completada', {
        position: 'top-right',
        autoClose: 2000,
      });
      setAnotaciones(anotaciones.map((anotacion) =>
        anotacion._id === id ? { ...anotacion, tarea: 'Completada' } : anotacion
      ));
    } catch (error) {
      console.error(error);
      toast.error('Error al completar la tarea');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const infoNote = {
      expedient_id: idExpedient,
      anotacion,
      fecha: new Date(),
      user_id: usuario.id,
      creator_name: usuario.name,
      creator_role: usuario.role,
      tarea,
    };

    try {
      await axios.post(`${baseAPI}/annotations/`, infoNote);
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error('Error al añadir la anotación');
    }
  };

  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="p-5">
        {anotaciones.length < 1 ? (
          <div className="text-center text-gray-500 py-5">
            <p className="text-lg">Las anotaciones que añadas aparecerán aquí</p>
          </div>
        ) : (
          anotaciones.map((nota) => (
            <Note
              key={nota._id}
              anotacion={nota}
              onCompletedTask={handleCompletedTask}
              activeRole={usuario.role}
            />
          ))
        )}

        <form className="mt-10" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-semibold text-gray-700">Nueva anotación</label>
            <textarea
              className="w-full mt-2 p-3 rounded-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              rows="4"
              placeholder="Escribe aquí tu anotación"
              value={anotacion}
              onChange={(e) => setAnotacion(e.target.value)}
            />
          </div>

          <div className="flex items-center mb-5">
            <label className="block text-sm font-semibold text-gray-700 mr-3">
              {usuario.role === 'admin' ? '¿Es una tarea para el cliente?' : '¿Es una tarea para el equipo?'}
            </label>
            <input
              type="checkbox"
              className="h-4 w-4 text-indigo-600"
              onChange={() => setTarea('Pendiente')}
            />
          </div>

          <button
            type="submit"
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg transition duration-200"
          >
            Añadir anotación
          </button>
        </form>
      </div>
    </div>
  );
};

export default NotesExpedient;
