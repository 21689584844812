import React, { useState, useEffect } from "react";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const ImportesRecuperados = ({ importe }) => (
  <tr>
    <td className="px-4 py-2">
      <div className="flex items-center">
        <div className="text-gray-800">{importe.fecha.substring(0, 10)}</div>
      </div>
    </td>
    <td className="px-4 py-2">
      <div className="text-gray-800">{importe.concept}</div>
    </td>
    <td className="px-4 py-2 text-right text-green-500">
      <CurrencyFormat
        value={importe.importe}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        suffix={"€"}
      />
    </td>
  </tr>
);

const MoneyComeBack = ({ idExpedient }) => {
  const [amount, setAmount] = useState(0);
  const [recoveryDate, setRecoveryDate] = useState("");
  const [expedientId] = useState(idExpedient);
  const [importesRecuperados, setImportesRecuperados] = useState([]);
  const [importeDeuda, setImporteDeuda] = useState("");
  const [concept, setConcept] = useState("");
  const [totalDeudaPendiente, setTotalDeudaPendiente] = useState(0);
  const [expedienteCreado, setExpedienteCreado] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseAPI}/expedients/${idExpedient}`);
        const importes = response.data.importes_recuperados;
        const totalRecuperados = importes.map((t) => t.importe);
        let deudaPendiente = response.data.importe_deuda - totalRecuperados.reduce((a, v) => a + v, 0);
        deudaPendiente = Math.round(deudaPendiente * 100) / 100;
        let importeDeuda = response.data.importe_deuda;
        importeDeuda = Math.round(importeDeuda * 100) / 100;

        setImportesRecuperados(importes);
        setImporteDeuda(importeDeuda);
        setExpedienteCreado(response.data.createdAt);
        setTotalDeudaPendiente(
          deudaPendiente
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [idExpedient]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!amount || !recoveryDate || !concept) {
      return toast.error("Debes rellenar todos los campos");
    }

    const infoAmountRecovery = {
      importe: amount,
      fecha: recoveryDate,
      concept,
    };

    try {
      await axios.put(
        `${baseAPI}/expedients/recoveryamount/${expedientId}`,
        infoAmountRecovery
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error("Error al añadir el importe recuperado");
    }
  };

  const sortedImportesRecuperados = [...importesRecuperados].sort(
    (a, b) => new Date(b.fecha) - new Date(a.fecha)
  );

  return (
    <div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-3 border-b border-gray-100">
        <h2 className="font-semibold text-green-500 text-right">
          Saldo:{" "}
          <CurrencyFormat
            className="font-semibold text-green-500 text-right"
            value={totalDeudaPendiente}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            suffix={"€"}
          />
        </h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold text-gray-600 bg-gray-100 rounded-sm">
              <tr>
                <th className="px-4 py-2">
                  <div className="text-left">Fecha</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Concepto</div>
                </th>
                <th className="px-4 py-2 text-right">
                  <div className="text-right">Importe</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-gray-800 divide-y divide-gray-200">
              {sortedImportesRecuperados.map((importe) => (
                <ImportesRecuperados importe={importe} key={importe._id} />
              ))}
              <tr>
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    <div>{expedienteCreado.substring(0, 10)}</div>
                  </div>
                </td>
                <td className="px-4 py-2">Deuda</td>
                <td className="px-4 py-2 text-right text-red-500">
                  -{" "}
                  <CurrencyFormat
                    value={importeDeuda}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix={"€"}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <form
            className="flex flex-wrap items-center justify-center mt-10 mb-20"
            onSubmit={handleSubmit}
          >
            <div className="w-full md:w-1/3 px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                htmlFor="amount"
              >
                Importe
              </label>
              <input
                id="amount"
                type="number"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                placeholder="importe recuperado"
                onChange={(e) => setAmount(e.target.value)}
                step="0.01"  // Permitir decimales
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                htmlFor="recoveryDate"
              >
                Fecha importe recuperado
              </label>
              <input
                type="date"
                id="recoveryDate"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                onChange={(e) => setRecoveryDate(e.target.value)}
              />
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6">
              <label
                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
                htmlFor="concept"
              >
                Concepto
              </label>
              <select
                className="w-full hover:bg-blue-200 py-2 rounded shadow-lg hover:shadow-xl"
                onChange={(e) => setConcept(e.target.value)}
                defaultValue="-Selecciona una opción-"
              >
                <option>-Selecciona una opción-</option>
                <option>Acuerdo extrajudicial</option>
                <option>Embargo Judicial</option>
                <option>Compensación</option>
                <option>Voluntariamente</option>
                <option>Decreto de adjudicación bien inmueble</option>
                <option>Decreto de adjudicación bien mueble</option>
                <option>Mediación</option>
                <option>Dación en pago</option>
                <option>Insolvencia</option>
                <option>Cancelación de deuda</option>
              </select>
            </div>
            <div className="w-full text-center mt-6">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
              >
                Añadir importe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MoneyComeBack;
