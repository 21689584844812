let urlBackend;
let stripeKey;
let stripeSecretKey;
let frontendUrl;

switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
        urlBackend = process.env.REACT_APP_PROD_BACKEND_URL;
        stripeKey = process.env.REACT_APP_STRIPE_API_KEY;
        stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
        frontendUrl = process.env.REACT_APP_PROD_FRONTEND_URL;
        break;
    case 'development':
        urlBackend = process.env.REACT_APP_DEV_BACKEND_URL;
        stripeKey = process.env.REACT_APP_STRIPE_API_KEY_TEST;
        stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY_TEST;
        frontendUrl = process.env.REACT_APP_DEV_FRONTEND_URL;
        break;
    case 'localhost':
        urlBackend = process.env.REACT_APP_LOCALHOST_BACKEND_URL;
        stripeKey = process.env.REACT_APP_STRIPE_API_KEY_TEST; // o una clave específica para staging si es necesario
        stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY_TEST; // o una clave específica para staging si es necesario
        frontendUrl = process.env.REACT_APP_LOCALHOST_FRONTEND_URL;
        break;
    default:
        // Configuración por defecto (puede ser desarrollo local, por ejemplo)
        urlBackend = process.env.REACT_APP_DEV_BACKEND_URL;
        stripeKey = process.env.REACT_APP_STRIPE_API_KEY_TEST;
        stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY_TEST;
}
const config = {
    baseAPI: urlBackend,
    REACT_APP_AWS_ACCESS_ID: process.env.REACT_APP_AWS_ACCESS_ID, 
    REACT_APP_AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
    stripeKey,
    stripeSecretKey,
    frontendUrl,
}

module.exports = config;
