import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { baseAPI, stripeKey } from '../../config';

const stripePromise = loadStripe(stripeKey);

const checkOutCreateExpedient = async (amount, expedient, role) => {
  const stripe = await stripePromise;

  const handleCompanyRole = async () => {
    try {
      const response = await axios.post(`${baseAPI}/expedients/create-checkout-session`, { amount, expedient, role });
      if (response.status === 200) {
        window.location = "/expedients";
      }
    } catch (error) {
      console.error(error);
      alert('Ocurrió un error al crear el expediente. Por favor, inténtelo de nuevo.');
    }
  };

  const handleOtherRoles = async () => {
    try {
      const response = await axios.post(`${baseAPI}/expedients/create-checkout-session`, { amount, expedient, role });
      const result = await stripe.redirectToCheckout({ sessionId: response.data.id });

      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error(error);
      alert('Ocurrió un error al crear la sesión de pago. Por favor, inténtelo de nuevo.');
    }
  };

  if (role === 'company') {
    await handleCompanyRole();
  } else {
    await handleOtherRoles();
  }
};

export default checkOutCreateExpedient;
