import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { baseAPI } from "../../config";
import UpdateDebtor from "./UpdateDebtorModal";
import jwt_decode from "jwt-decode";

const Debtors = ({ idExpedient }) => {
  const [deudores, setDeudores] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [crearDeudores, setCrearDeudores] = useState(false);
  const [isWhatsappModalOpen, setIsWhatsappModalOpen] = useState(false);
  const [whatsappMessage, setWhatsappMessage] = useState("");
  const [selectedDebtorId, setSelectedDebtorId] = useState(null);
  const [nuevoDeudor, setNuevoDeudor] = useState({
    nombre: "",
    domicilio: "",
    ciudad: "",
    cp: "",
    telefono: "",
    mail: "",
  });

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    const decoded = jwt_decode(userToken);
    setUserRole(decoded.role);

    const fetchExpediente = async () => {
      try {
        const response = await axios.get(
          `${baseAPI}/expedients/${idExpedient}`
        );
        setDeudores(response.data.deudores_id || []);
      } catch (error) {
        console.error("Error fetching expediente:", error);
      }
    };

    fetchExpediente();
  }, [idExpedient]);

  const handleNuevoDeudorChange = (e) => {
    const { name, value } = e.target;
    setNuevoDeudor({ ...nuevoDeudor, [name]: value });
  };

  const handleSubmitNuevoDeudor = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${baseAPI}/expedients/adddebtor`, {
        ...nuevoDeudor,
        expedient_id: idExpedient,
      });
      toast.success("Deudor añadido correctamente 😊");
      window.location.reload();
    } catch (error) {
      console.error("Error añadiendo deudor:", error);
      toast.error("Error al añadir el deudor 😢");
    }
  };

  const openWhatsappModal = (debtorId) => {
    setSelectedDebtorId(debtorId);
    setIsWhatsappModalOpen(true);
  };

  const handleSendWhatsapp = async (e) => {
    e.preventDefault();
    try {
      const userToken = localStorage.getItem("token");
      const decoded = jwt_decode(userToken);
      await axios.post(`${baseAPI}/actions/sendwhatsapp`, {
        debtorId: selectedDebtorId,
        expedientId: idExpedient,
        userSesion: decoded,
        message: whatsappMessage,
      });
      toast.success("WhatsApp enviado correctamente 📲");
      setWhatsappMessage("");
      setIsWhatsappModalOpen(false);
    } catch (error) {
      console.error("Error enviando el WhatsApp:", error);
      toast.error("Error al enviar el WhatsApp 😞");
    }
  };

  const handleEmailToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres enviar el mail?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwt_decode(userToken);
        await axios.post(`${baseAPI}/actions/sendEmailToDebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("Email enviado correctamente ✉️");
      } catch (error) {
        console.error("Error enviando el email:", error);
        toast.error("Error al enviar el email 😞");
      }
    }
  };

  const handleSmsToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres enviar un SMS?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwt_decode(userToken);
        await axios.post(`${baseAPI}/actions/sendsmstodebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("SMS enviado correctamente 📱");
      } catch (error) {
        console.error("Error enviando el SMS:", error);
        toast.error("Error al enviar el SMS 😞");
      }
    }
  };

  const handleCallToDebtor = async (debtorId) => {
    if (window.confirm("¿Seguro que quieres realizar una llamada?")) {
      try {
        const userToken = localStorage.getItem("token");
        const decoded = jwt_decode(userToken);
        await axios.post(`${baseAPI}/actions/calltodebtor`, {
          debtorId,
          expedientId: idExpedient,
          userSesion: decoded,
        });
        toast.success("Llamada realizada correctamente 📞");
      } catch (error) {
        console.error("Error realizando la llamada:", error);
        toast.error("Error al realizar la llamada 😞");
      }
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {deudores.map((deudor) => (
          <div
            key={deudor._id}
            className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between"
          >
            <div>
              <h3 className="text-xl sm:text-2xl font-bold text-blue-800 truncate">
                {deudor.debtorname}
              </h3>
              <p className="mt-2 text-sm text-gray-600">
                📞 {deudor.debtorphone}
              </p>
              <p className="text-sm text-gray-600 mt-2">
                🏠 {deudor.debtoradress}, {deudor.debtorcity},{" "}
                {deudor.debtorzip}
              </p>
            </div>
            <div className="mt-4">
              <span className="inline-block bg-blue-100 text-blue-600 text-xs font-semibold px-2 py-1 rounded-full shadow-sm">
                Deudor Activo
              </span>
            </div>

            {userRole === "admin" && (
              <div className="mt-6 grid grid-cols-1 gap-3 sm:grid-cols-2">
                <button
                  onClick={() => handleEmailToDebtor(deudor._id)}
                  className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 rounded-lg transition-colors duration-200 w-full"
                >
                  ✉️ <span className="ml-2">Enviar Email</span>
                </button>
                <button
                  onClick={() => handleSmsToDebtor(deudor._id)}
                  className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white font-medium py-2 rounded-lg transition-colors duration-200 w-full"
                >
                  📱 <span className="ml-2">Enviar SMS</span>
                </button>
                <button
                  onClick={() => handleCallToDebtor(deudor._id)}
                  className="flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 rounded-lg transition-colors duration-200 w-full"
                >
                  📞 <span className="ml-2">Llamar</span>
                </button>
                <UpdateDebtor idDebtor={deudor._id} />
                <button
                  onClick={() => openWhatsappModal(deudor._id)}
                  className="flex items-center justify-center bg-green-700 hover:bg-green-800 text-white font-medium py-2 rounded-lg transition-colors duration-200 w-full"
                >
                  📲 <span className="ml-2">Enviar WhatsApp</span>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      {isWhatsappModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="bg-white p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">
                  Enviar WhatsApp Personalizado
                </h3>
                <form onSubmit={handleSendWhatsapp}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Mensaje:
                    </label>
                    <textarea
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      rows="4"
                      value={whatsappMessage}
                      onChange={(e) => setWhatsappMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700"
                      onClick={() => setIsWhatsappModalOpen(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md"
                    >
                      Enviar WhatsApp
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {!crearDeudores ? (
        <button
          onClick={() => setCrearDeudores(true)}
          className="mt-8 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg transition duration-200"
        >
          Añadir deudor
        </button>
      ) : (
        <form className="mt-8" onSubmit={handleSubmitNuevoDeudor}>
          <h3 className="text-lg font-semibold mb-4">Añadir un nuevo Deudor</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {["nombre", "domicilio", "ciudad", "cp", "telefono", "mail"].map(
              (campo) => (
                <div key={campo}>
                  <label className="block text-sm font-medium text-gray-700 capitalize">
                    {campo}
                  </label>
                  <input
                    type="text"
                    name={campo}
                    value={nuevoDeudor[campo]}
                    onChange={handleNuevoDeudorChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
              )
            )}
          </div>
          <button
            type="submit"
            className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg transition duration-200"
          >
            Guardar deudor
          </button>
        </form>
      )}
    </div>
  );
};

export default Debtors;
