import React, { useState } from "react";
import { read, utils } from "xlsx";
import axios from "axios";
import { baseAPI } from "../config";
import { toast } from "react-toastify";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";

const ImportExpedients = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expedients, setExpedients] = useState([]);

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      const bufferArray = e.target.result;
      const wb = read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_json(ws);
      setExpedients(data);
      console.log(data);
    };
    fileReader.onerror = (error) => {
      console.log(error);
    };
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const createExpedients = async (e) => {
    e.preventDefault();
    try {
      for (const expedient of expedients) {
        const servicios = [
          {
            tipo: "burofax",
            fecha: new Date(),
            estado: "pendiente",
            costo: 0,
            detalles: "Envío de burofax certificado al deudor",
            cantidad: 999,
            utilizados: 0,
          },
          {
            tipo: "llamada_robotizada",
            fecha: new Date(),
            estado: "pendiente",
            costo: 0,
            detalles: "Envío de llamada robotizada",
            cantidad: 999,
            utilizados: 0,
          },
          {
            tipo: "llamada_personal",
            fecha: new Date(),
            estado: "pendiente",
            costo: 0,
            detalles: "Envío de llamada personal",
            cantidad: 999,
            utilizados: 0,
          },
          {
            tipo: "sms",
            fecha: new Date(),
            estado: "pendiente",
            costo: 0,
            detalles: "Envío de sms",
            cantidad: 999,
            utilizados: 0,
          },
          {
            tipo: "email",
            fecha: new Date(),
            estado: "pendiente",
            costo: 0,
            detalles: "Envío de email",
            cantidad: 999,
            utilizados: 0,
          },
        ];
        expedient.servicios = servicios;
        await axios.post(`${baseAPI}/expedients/add`, expedient).then((res) => {
          if (res.status === 200) {
            if (res.data.type === "create") {
              toast.success("Expediente Creado! 😄", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (res.data.type === "update") {
              toast.info("Expediente Actualizado! 🔄 ", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.error("Error al crear expedientes! 😞", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      }
      toast.success("Expedientes creados correctamente! 😄", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        window.location = "/expedients";
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Error al crear expedientes! 😞", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="mt-10 mx-10">
          <div className="flex justify-center mt-4">
            {expedients.length > 0 && (
              <button
                className="w-full md:w-1/3 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                type="submit"
                onClick={createExpedients}
              >
                Importar expedientes
              </button>
            )}
          </div>

          <div className="mt-4 flex justify-center">
            {expedients.length === 0 && (
              <>
                <div className="flex flex-col items-center mt-4">
                  <a
                    className="sm:px-3 lg:px-3 py-3 text-blue-600 hover:text-blue-800 text-center rounded-md mb-3"
                    href="https://docs.google.com/spreadsheets/d/1JLB5RD2X6vbbukuUY4h9f3ohf8AplU--/edit?usp=share_link&ouid=110409759592739368353&rtpof=true&sd=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Descargar plantilla importadora
                  </a>
                  <div
                    className="px-4 sm:px-6 lg:px-8 py-8 w-full bg-blue-300 hover:bg-blue-400 text-white text-center rounded-md border-2 border-dashed border-blue-500"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                  >
                    <p>
                      Arrastra el fichero aqui para importar los expedientes
                    </p>
                  </div>
                </div>
              </>
            )}
            {expedients.length > 0 && (
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                      <tr>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">id_key</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">user_id</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">deudor</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            importe_deuda
                          </div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">
                            tipo_reclamacion
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {expedients.map((expedient) => (
                        <tr key={expedient.id_key}>
                          <td className="p-2 whitespace-nowrap">
                            {expedient.id_key}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {expedient.user_id}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {expedient.deudor}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {expedient.importe_deuda}
                          </td>
                          <td className="p-2 whitespace-nowrap">
                            {expedient.tipo_reclamacion}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ImportExpedients;
