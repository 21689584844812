import React, { useState, useEffect } from "react";
import imageService1 from "../images/claimup-reclamacion-software1.jpg";
import imageService2 from "../images/claimup-reclamacion-software2.jpg";
import imageService3 from "../images/claimup-reclamacion-software3.jpg";
import imageService4 from "../images/claimup-reclamacion-software4.jpg";
import imageService5 from "../images/claimup-reclamacion-software5.jpg";

import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = {
  title: "Gestión y Seguimiento de Deudas",
  description: "Control total y seguimiento en tiempo real de tus deudas",
  paragraph:
    "Administra eficientemente todas tus deudas en un solo lugar con nuestro dashboard personalizado. Obtén visibilidad completa y actualizaciones en tiempo real para optimizar tu flujo de trabajo de recuperación de deudas.",
  imageUrl: imageService3,
  allFeatures: [
    {
      name: "Dashboard Personalizado: ",
      description:
        "Monitorea y gestiona todas tus deudas con nuestro sistema centralizado, ofreciendo un acceso rápido a los KPIs críticos y un análisis detallado de tu cartera de deudas.",
      icon: CloudArrowUpIcon, // Manteniendo el icono original para este ejemplo
    },
    {
      name: "Seguimiento en Tiempo Real: ",
      description:
        "Mantente informado con actualizaciones en tiempo real sobre el estado de tus deudas, permitiéndote tomar decisiones basadas en datos para una recuperación efectiva.",
      icon: LockClosedIcon, // Manteniendo el icono original para este ejemplo
    },
    {
      name: "Alertas y Notificaciones: ",
      description:
        "Recibe alertas y notificaciones instantáneas para cualquier cambio o actualización en el estado de tus deudas, asegurando que nunca te pierdas de una acción importante.",
      icon: ServerIcon, // Manteniendo el icono original para este ejemplo
    },
  ],
};

const claimsPanelFeatures = {
  title: "Panel de Reclamaciones",
  description: "Visión completa de cada caso de reclamación",
  paragraph:
    "Accede a una visión 360° de tus reclamaciones, desde el estado actual hasta el historial completo de interacciones y documentación relevante, todo desde un único panel intuitivo.",
  imageUrl: imageService1,
  allFeatures: [
    {
      name: "Estado y Seguimiento de Expedientes: ",
      description:
        "Visualiza en tiempo real el estado de cada expediente de reclamación y realiza un seguimiento detallado de las etapas completadas y pendientes.",
      icon: CloudArrowUpIcon, // Se sugiere buscar un icono más representativo si es posible
    },
    {
      name: "Historial de Interacciones y Documentación: ",
      description:
        "Consulta el historial completo de interacciones con cada deudor y accede fácilmente a toda la documentación relevante de cada caso.",
      icon: LockClosedIcon, // Se sugiere buscar un icono más representativo si es posible
    },
    {
      name: "Gestión de Acciones Programadas: ",
      description:
        "Visualiza y gestiona las acciones programadas para cada reclamación, incluyendo comunicaciones formales y procedimientos judiciales.",
      icon: ServerIcon, // Se sugiere buscar un icono más representativo si es posible
    },
  ],
};

const omnichannelCommunicationFeatures = {
  title: "Comunicación Omnicanal",
  description: "Estrategias personalizadas para cada deudor",
  paragraph:
    "Maximiza la eficacia de tus esfuerzos de recobro con un enfoque de comunicación omnicanal. Utilizamos la inteligencia artificial para adaptar nuestros mensajes y canales de comunicación según la situación específica de cada deuda, asegurando una interacción personal y efectiva.",
  imageUrl: imageService2,
  allFeatures: [
    {
      name: "Personalización con IA: ",
      description:
        "Aplicamos inteligencia artificial para personalizar las estrategias de comunicación, optimizando los mensajes según el perfil y comportamiento de cada deudor.",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Múltiples Canales: ",
      description:
        "Interactuamos a través de diversos canales —email, SMS, llamadas telefónicas, redes sociales— para aumentar las posibilidades de contacto efectivo con el deudor.",
      icon: LockClosedIcon,
    },
    {
      name: "Seguimiento y Adaptación: ",
      description:
        "Realizamos seguimiento en tiempo real de la eficacia de cada canal y mensaje, adaptándonos dinámicamente para mejorar continuamente nuestras tácticas de recobro.",
      icon: ServerIcon,
    },
  ],
};

const paymentPlatformFeatures = {
  title: "Facilidades de Pago",
  description: "Soluciones flexibles para la recuperación de deudas",
  paragraph:
    "Facilitamos el proceso de pago para los deudores a través de una plataforma segura y adaptable. Con nuestra pasarela de pago integrada y opciones de pago personalizadas, aseguramos una experiencia de pago sin fricciones, aumentando las tasas de recuperación de deudas.",
  imageUrl: imageService5,
  allFeatures: [
    {
      name: "Pasarela de Pago Segura: ",
      description:
        "Ofrecemos una experiencia de pago segura y confiable a través de nuestra integración con Stripe, garantizando transacciones seguras para cada deudor.",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Opciones de Pago Adaptadas: ",
      description:
        "Proporcionamos diversas opciones de pago adaptadas a la situación financiera de cada deudor, permitiendo pagos flexibles y accesibles.",
      icon: LockClosedIcon,
    },
    {
      name: "Gestión de Cobros Masivos: ",
      description:
        "Nuestra plataforma permite la gestión eficiente de cobros masivos, facilitando la conciliación automática y asegurando la precisión en el seguimiento de pagos.",
      icon: ServerIcon,
    },
  ],
};

const integrationFeatures = {
  title: "Integraciones Avanzadas",
  description:
    "Conexión fluida con sistemas ERP y herramientas de importación eficientes",
  paragraph:
    "Simplifica la gestión de deudas integrándote sin esfuerzo con el ERP de tu empresa para actualizar automáticamente el estado de los deudores y añadir nuevos de forma automática. Nuestra herramienta de importación sencilla permite cargar miles de deudas rápidamente, optimizando el proceso de gestión de deudas desde el inicio.",
  imageUrl: imageService4,
  allFeatures: [
    {
      name: "Integración con ERP: ",
      description:
        "Actualiza automáticamente el estado de los deudores y añade nuevos de forma directa en tu sistema ERP, manteniendo tus registros siempre al día.",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Herramienta de Importación: ",
      description:
        "Carga miles de deudas de forma sencilla y rápida con nuestra herramienta de importación, diseñada para simplificar el proceso de incorporación de deudas.",
      icon: LockClosedIcon,
    },
    {
      name: "Sincronización Automática: ",
      description:
        "Nuestras integraciones aseguran una sincronización automática y en tiempo real, permitiendo una gestión de deudas más eficiente y menos propensa a errores.",
      icon: ServerIcon,
    },
  ],
};

const featureSets = [
  features,
  claimsPanelFeatures,
  omnichannelCommunicationFeatures,
  paymentPlatformFeatures,
  integrationFeatures,
];

function NewFeatures() {
  const [featureIndex, setFeatureIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFeatureIndex((prevIndex) => (prevIndex + 1) % featureSets.length); // Cicla a través de los índices de los conjuntos de características
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const currentFeatures = featureSets[featureIndex];

  return (
    <section className="relative">
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">
                  {currentFeatures.title}
                </h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {currentFeatures.description}
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {currentFeatures.paragraph}
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {currentFeatures.allFeatures.map((feature, index) => (
                    <div key={index} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            {/* Asegúrate de tener una imagen o eliminar este elemento si no es necesario */}
            <img
              src={currentFeatures.imageUrl}
              alt="Product screenshot"
              className="w-[48rem] mt-20 max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </div>
        </div>
             {/* Controles para cambiar de característica */}
      <div className="flex justify-center space-x-4 mt-8">
          <button
            onClick={() =>
              setFeatureIndex((prevIndex) =>
                prevIndex === 0 ? featureSets.length - 1 : prevIndex - 1
              )
            }
            className="flex items-center justify-center w-10 h-10 p-1 text-gray-600 bg-white rounded-full shadow-lg hover:shadow-xl"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button
            onClick={() =>
              setFeatureIndex((prevIndex) => (prevIndex + 1) % featureSets.length)
            }
            className="flex items-center justify-center w-10 h-10 p-1 ml-4 text-gray-600 bg-white rounded-full shadow-lg hover:shadow-xl"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M9 5l7 7-7 7" />
            </svg>
          </button>
          </div>

      </div>
    </section>
  );
}

export default NewFeatures;
