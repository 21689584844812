import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="body-bg min-h-screen pt-12 md:pt-20 pb-6 px-2 md:px-0">
        <main className="bg-white max-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-2xl">
          <section>
            <h1>Política de Privacidad de CLAIM UP SL</h1>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Introducción</h2>
            <p>
              Bienvenido a www.claimup.io. Esta política de privacidad explica cómo CLAIM UP SL, con domicilio social en Calle Fray Ceferino, 21 - Piso 3 Iz, 33001 Oviedo, Asturias, España y CIF B56216120 (en adelante, "nosotros", "nos" o "nuestro"), recopila, utiliza, comparte y protege su información personal.
            </p>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Información Recopilada</h2>
            <p>
              Cuando utiliza nuestro sitio web, como parte del proceso, recopilamos la información personal que nos proporciona, como su nombre, dirección y dirección de correo electrónico. Su información personal se utilizará únicamente para los motivos específicos indicados a continuación.
            </p>

            <h
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Por qué recopilamos información personal</h>
            <p>
              Recopilamos dicha información personal y no personal con los siguientes fines:
            </p>
            <ul>
              <li>Para proporcionar y operar los Servicios;</li>
              <li>Para proporcionar a nuestros Usuarios asistencia continua al cliente y soporte técnico;</li>
              <li>Para poder contactar a nuestros Visitantes y Usuarios con avisos generales o personalizados relacionados con el servicio y mensajes promocionales;</li>
              <li>Para crear datos estadísticos agregados y otra información no personal agregada y / o inferida, que podemos utilizar para proporcionar y mejorar nuestros respectivos servicios;</li>
              <li>Para cumplir con cualquier ley y regulación aplicable.</li>
            </ul>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Almacenamiento de datos</h2>
            <p>
              Nuestro sitio web se encuentra alojado en [Nombre del alojamiento web]. Se proporcionan a nuestro sitio web las plataformas de comercio electrónico en línea que nos permiten vender nuestros productos y servicios. Sus datos se almacenan a través del almacenamiento de datos de la aplicación de datos de la aplicación de datos y las bases de datos de la aplicación general de la aplicación, en un servidor seguro detrás de un firewall.
            </p>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Seguridad</h2>
            <p>
              Para proteger su información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no se pierda, use indebidamente, acceda, divulgue, altere o destruya de manera inapropiada.
            </p>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Compartir datos personales</h2>
            <p>
              Podemos compartir su información personal con terceros para ayudarnos a utilizar su información personal, como se describe anteriormente. Por ejemplo, podemos utilizar un proveedor de servicios de terceros para procesar pagos realizados a través de los Servicios. Cuando compartimos datos personales con terceros, tomamos medidas razonables para garantizar que los datos se procesen de acuerdo con esta Política de privacidad y las leyes de protección de datos aplicables.
            </p>
            <p>
              Además, podemos divulgar o transferir su información personal:
            </p>
            <ul>
              <li>Para cumplir con un requisito legal, como la ley, un proceso legal, una orden judicial o un mandato judicial, o cuando creemos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger su seguridad o la seguridad de otros, investigar fraude o responder a una solicitud del gobierno;</li>
              <li>Con nuestros proveedores de servicios de confianza que trabajan en nuestro nombre, no tienen un uso independiente de la información que revelamos a ellos y han aceptado adherirse a las reglas establecidas en esta política de privacidad.</li>
            </ul>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Transferencias de datos</h2>
            <p>
              Podemos transferir información personal a países que no sean el país en el que se recopiló la información. Estos países pueden tener leyes de protección de datos diferentes a las leyes de su país.
            </p>
            <p>
              Si se encuentra en la Unión Europea ("UE"), sus datos personales se procesarán fuera de la UE, incluidos en los Estados Unidos; estos países pueden tener leyes de protección de datos diferentes a las leyes de la UE.
            </p>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Retención de datos</h2>
            <p>
              Conservaremos su información personal durante el tiempo que sea necesario para proporcionarle nuestros servicios, cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos.
            </p>
            <p>
              CLAIM UP SL también conservará los datos de uso de forma anónima durante fines de análisis.
            </p>

            <h2
            className="text-2xl font-bold text-gray-800 mt-10 mb-3"
            >Contacto</h2>
            <p>
              Si tiene preguntas sobre esta política de privacidad, contáctenos en:
              CLAIM UP SL
              Calle Fray Ceferino, 21 - Piso 3 Iz
              33001 Oviedo, Asturias, España
              Email: [correo electrónico de contacto]
            </p>
          </section>
        </main>
        <footer className="max-w-lg mx-auto flex justify-center text-white">
          <a href="www.claimup.io" className="hover:underline">
            www.claimup.io
          </a>
        </footer>
      </div>
    );
  }
}
