import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto"; // Asegúrate de importar Chart.js
import Tooltip from "../components/Tooltip";

const DashboardCard09 = ({ chartData }) => {
  const [viewMode, setViewMode] = useState("day"); // Estado para controlar si mostramos por día o por mes
  const [processedData, setProcessedData] = useState(chartData); // Datos procesados según la selección

  useEffect(() => {
    // Función para agrupar los datos por mes
    const groupDataByMonth = (data) => {
      const groupedData = data.labels.reduce((acc, label, index) => {
        const month = new Date(label).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
        if (!acc[month]) acc[month] = 0;
        acc[month] += data.datasets[0].data[index];
        return acc;
      }, {});
      return {
        labels: Object.keys(groupedData),
        datasets: [
          {
            label: "Importes Recuperados",
            data: Object.values(groupedData),
            backgroundColor: "#4F46E5",
            hoverBackgroundColor: "#4338CA",
            borderRadius: 5,
            borderWidth: 0,
            barPercentage: 0.7,
            categoryPercentage: 0.6,
          },
        ],
      };
    };

    // Procesar los datos según la vista seleccionada
    if (viewMode === "month") {
      setProcessedData(groupDataByMonth(chartData));
    } else {
      setProcessedData(chartData);
    }
  }, [viewMode, chartData]);

  const barChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false, // Ocultar la leyenda si no es necesaria
      },
      tooltip: {
        mode: "index",
        intersect: false,
        backgroundColor: "#4F46E5",
        titleColor: "#FFFFFF",
        bodyColor: "#FFFFFF",
        borderColor: "#FFFFFF",
        borderWidth: 1,
        xPadding: 10,
        yPadding: 10,
        cornerRadius: 4,
        displayColors: false,
        callbacks: {
          label: (tooltipItem) => `€${tooltipItem.formattedValue}`,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#6B7280",
        },
      },
      y: {
        grid: {
          display: true,
          color: "#E5E7EB",
          borderDash: [4, 4],
        },
        ticks: {
          beginAtZero: true,
          callback: (value) => `€${value.toLocaleString()}`,
          color: "#6B7280",
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-md rounded-lg border border-gray-200">
      <header className="px-6 py-4 bg-white shadow-sm rounded-md flex items-center justify-between">
        <div className="flex items-center">
          <h2 className="font-bold text-xl text-gray-800">
            Importes Recuperados
          </h2>
          <Tooltip className="ml-3" size="lg">
            <div className="text-sm text-gray-600">
              Representa los importes recuperados por{" "}
              {viewMode === "day" ? "día" : "mes"}.
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center">
          <label
            className="mb-2 sm:mb-0 sm:mr-3 font-medium text-gray-700 text-sm"
            htmlFor="viewModeSelector"
          >
            Ver por:
          </label>
          <select
            id="viewModeSelector"
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
            className="p-2 border border-gray-300 rounded-md text-sm bg-gray-50 hover:bg-gray-100 transition-colors w-full sm:w-56"
          >
            <option value="day">Por Día</option>
            <option value="month">Por Mes</option>
          </select>
        </div>
      </header>

      <div className="px-5 py-3">
        <div className="flex items-start"></div>
      </div>
      {/* Gráfico construido con react-chartjs-2 con margen ajustado */}
      <div className="flex-grow" style={{ height: "300px", padding: "15px" }}>
        {/* Verificar si processedData está correctamente definido */}
        {processedData && processedData.labels.length > 0 ? (
          <Bar data={processedData} options={barChartOptions} />
        ) : (
          <div className="text-center text-gray-500">
            No hay datos disponibles para mostrar.
          </div>
        )}
      </div>
    </div>
  );
};
export default DashboardCard09;
