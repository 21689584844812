import React, { Component } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";

const Users = (props) => (
  <tr>
    <td className="p-2 whitespace-nowrap">
      <div className="flex items-center">
        <div className="font-medium text-gray-800">{props.user.username}</div>
      </div>
    </td>
    <td className="p-2 whitespace-nowrap">
      <div className="text-left">{props.user.userlastname}</div>
    </td>
    <td className="p-2 whitespace-nowrap">
      <div className="text-left font-medium text-gray-500">
        {props.user.usermail}
      </div>
    </td>
    <td className="p-2 whitespace-nowrap">
      <div className="text-left font-medium text-gray-500">
        {props.user.userphone}
      </div>
    </td>
    <td className="p-2 whitespace-nowrap">
      <button
        className="text-gray-400 hover:text-gray-500 rounded-full"
        onClick={() => {
          props.viewUser(props.user._id);
        }}
      >
        <span className="sr-only">Edit</span>
        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
          <path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z" />
        </svg>
      </button>
      <button
        className="text-red-500 hover:text-red-600 rounded-full"
        onClick={() => {
          props.deleteUser(props.user._id);
        }}
      >
        <span className="sr-only">Delete</span>
        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
          <path d="M13 15h2v6h-2zM17 15h2v6h-2z" />
          <path d="M20 9c0-.6-.4-1-1-1h-6c-.6 0-1 .4-1 1v2H8v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V13h1v-2h-4V9zm-6 1h4v1h-4v-1zm7 3v9H11v-9h10z" />
        </svg>
      </button>
    </td>
  </tr>
);

export default class UsersRegister extends Component {
  constructor(props) {
    super(props);
    this.deleteUser = this.deleteUser.bind(this);

    this.state = { users: [] };
  }
  componentDidMount() {
    axios
      .get(`${baseAPI}/users/`, {
        headers: { Authorization: localStorage.getItem("SavedToken") },
      })
      .then((response) => {
        this.setState({ users: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  deleteUser(id) {
    async function userDelete(id) {
      const response = await axios
        .delete(`${baseAPI}/users/${id}`)
        .catch((error) => {
          console.log(error);
        });
      return response;
    }
    var confirmacion = window.confirm(
      "¿Estás seguro de que quieres eliminar este usuario?"
    );
    if (confirmacion === true) {
      userDelete(id).then((response) => {
        if (response.status === 200) {
          toast.success("Usuario eliminado! 😄 ", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });
    }
  }

  viewUser(id) {
    window.location = `/profile/${id}`;
  }

  userList() {
    return this.state.users.map((currentuser) => {
      return (
        <Users
          user={currentuser}
          deleteUser={this.deleteUser}
          viewUser={this.viewUser}
          key={currentuser._id}
        />
      );
    });
  }
  render() {
    return (
      <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-gray-200">
        <header className="px-5 py-4 border-b border-gray-100">
          <h2 className="font-semibold text-gray-800">Usuarios registrados</h2>
        </header>
        <div className="p-3">
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Nombre</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Apellido</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Mail</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Telefono</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Acciones</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {this.userList()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
