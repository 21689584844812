/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import axios from "axios";
import { baseAPI } from "../../config";

const Documents = (props) => (
  <li className="flex px-2">
    <div className="w-9 h-9 rounded-full flex-shrink-0 bg-blue-500 my-2 mr-3 w-full">
      <svg className="w-9 h-9 fill-current text-blue-50" viewBox="0 0 36 36">
        <path d="M25 24H11a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1zM14 13h8v2h-8z" />
      </svg>
    </div>
    <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2 w-full">
      <div className="flex-grow flex justify-between">
        <div className="self-center w-full overflow-hidden">
          {" "}
          <a className="font-medium text-gray-800 truncate" href="#0">
            {props.document.nombredocumento}
          </a>
        </div>
        <div className="flex-shrink-0 self-end ml-2">
          <button
            className="text-red-500 hover:text-red-600 rounded-full"
            onClick={() => {
              props.viewDocument(props.document.url);
            }}
          >
            <span className="sr-only">Open</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-arrow-bar-to-down"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="4" y1="20" x2="20" y2="20" />
              <line x1="12" y1="14" x2="12" y2="4" />
              <line x1="12" y1="14" x2="16" y2="10" />
              <line x1="12" y1="14" x2="8" y2="10" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </li>
);

export default class FilesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentos: [],
      expedientId: props.idExpedient,
    };
  }

  componentDidMount() {
    const idExp = this.state.expedientId;

    axios
      .get(`${baseAPI}/expedients/` + idExp)
      .then((response) => {
        this.setState({
          documentos: response.data.documentacion,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  viewDocument(url) {
    window.open(url);
  }

  documentsList() {
    return this.state.documentos.map((currentdocuments) => {
      return (
        <Documents
          document={currentdocuments}
          viewDocument={this.viewDocument}
          key={currentdocuments._id}
        />
      );
    });
  }

  render() {
    return (
      <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 w-full">
        <div className="p-3">
          <div>
            <header className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2 w-full">
              Documentación subida
            </header>
            <ul className="my-1">{this.documentsList()}</ul>
          </div>
        </div>
      </div>
    );
  }
}
