import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseAPI } from "../../config";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

const AccountPanel = (props) => {
  const [userData, setUserData] = useState({
    username: "",
    userlastname: "",
    usermail: "",
    userphone: "",
    invoicedataname: "",
    invoicedataaddress: "",
    invoicedatacity: "",
    invoicedatacountry: "",
    invoicedatazip: "",
    invoicedatacif: "",
    invoicedataemail: "",
    judicialPercentage: "" , // Porcentaje judicial
    extrajudicialPercentage: "", // Porcentaje extrajudicial
  });

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userId = props.userId;
    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    setUserRole(decoded.role);

    axios
      .get(`${baseAPI}/users/` + userId)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.userId]);

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userId = props.userId;

    axios
      .post(`${baseAPI}/users/update/` + userId, userData)
      .then((res) => {
        toast.success("Usuario Actualizado! 😄", {
          position: "top-right",
          autoClose: 5000,
        });
        if (userRole !== "admin") {
          localStorage.setItem("token", res.data.token);
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        alert("Error al actualizar el usuario");
      });
  };

  // Definir los campos con permisos
  const userFields = [
    { id: "grid-username", label: "Nombre", name: "username", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-userlastname", label: "Apellido", name: "userlastname", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-usermail", label: "Email", name: "usermail", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-userphone", label: "Teléfono", name: "userphone", permissions: ["admin", "user", "client", "company"] },
  ];

  const invoiceFields = [
    { id: "grid-invoicename", label: "Nombre de facturación", name: "invoicedataname", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoiceaddress", label: "Dirección", name: "invoicedataaddress", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecity", label: "Ciudad", name: "invoicedatacity", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicezip", label: "Código Postal", name: "invoicedatazip", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecountry", label: "País", name: "invoicedatacountry", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoicecif", label: "CIF/NIF", name: "invoicedatacif", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-invoiceemail", label: "Email de facturación", name: "invoicedataemail", permissions: ["admin", "user", "client", "company"] },
    { id: "grid-judicialPercentage", label: "Porcentaje Judicial", name: "judicialPercentage", permissions: ["admin"] },  // Solo admin
    { id: "grid-extrajudicialPercentage", label: "Porcentaje Extrajudicial", name: "extrajudicialPercentage", permissions: ["admin"] },  // Solo admin
  ];

  const renderFields = (fields) => {
    return fields
      .filter((field) => field.permissions.includes(userRole))  // Filtrar por permisos
      .map((field) => (
        <div key={field.id}>
          <label className="block text-sm font-medium text-gray-600" htmlFor={field.id}>
            {field.label}
          </label>
          <input
            className="mt-1 block w-full rounded-md bg-gray-100 border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            id={field.id}
            type="text"
            name={field.name}
            value={userData[field.name] || ""}
            onChange={onChange}
          />
        </div>
      ));
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-7xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-200">
        <h2 className="font-semibold text-gray-800 text-xl">Panel de usuario</h2>
      </header>

      <section className="px-5 py-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Datos del Usuario</h3>
        <div className="bg-white shadow-md rounded-lg p-6">
          <form method="POST" onSubmit={onSubmit}>
            <div className="grid gap-6 md:grid-cols-2">
              {renderFields(userFields)}
            </div>
          </form>
        </div>
      </section>

      <section className="px-5 py-6">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Datos de Facturación</h3>
        <div className="bg-white shadow-md rounded-lg p-6">
          <form method="POST" onSubmit={onSubmit}>
            <div className="grid gap-6 md:grid-cols-2">
              {renderFields(invoiceFields)}
            </div>
            <div className="mt-6 flex justify-end">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md shadow-md transition duration-300"
                type="submit"
              >
                Guardar Cambios
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AccountPanel;
